//PC_HC_01 - PC_HC_03 = Import the statements and react
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postErrorLog, postLogData } from "../Service/api";
import axios from "axios";

//PS_HD-05
interface UserType {
	displayName: string;
	userPrincipalName: string;
	jobTitle: string;
	department: string;
	id: string;
	employeeId: string;
	givenName: string;
};

//PS_HD-04
function Header() {

	const navigate: any = useNavigate();

	//PS_HD-05 - set state variable.
	const [logo, setlogo] = useState<string>("");
	const [timeoutPopup, setTimeoutPopup] = useState(true);
	const [refreshToken, setRefreshToken] = useState<boolean>(false);
	const [viewLink, setviewLink] = useState<string>("");
	const [isLogOut, setIsLogOut] = useState(true)
	const [user, setUser] = useState<UserType>()


	//PS_HD-06  - user details are store in the local storage.
	let userDetails = {
		displayName:
			localStorage.getItem("displayName") === undefined
				? ""
				: localStorage.getItem("displayName"),
		emailId:
			localStorage.getItem("emailId") === undefined
				? ""
				: localStorage.getItem("emailId"),
		firstName:
			localStorage.getItem("firstName") === undefined
				? ""
				: localStorage.getItem("firstName"),
		lastName:
			localStorage.getItem("lastName") === undefined
				? ""
				: localStorage.getItem("lastName"),
	};

	// PC_HC_14 - PC_HC_17 - To get the User data in the graph microsoft api.
	useEffect(() => {
		fetchUserData();
	}, []);

	async function fetchUserData() {
		try {
			const accessToken = localStorage.getItem('token')
			if (accessToken) {
				await axios.get(`https://graph.microsoft.com/v1.0/me?$select=displayName,userPrincipalName,jobTitle,Department,id,employeeId,givenName&$top=999`, {
					headers: {
						Authorization: accessToken
					}
				}).then(response => {
					setUser(response?.data)
					localStorage.setItem("employeeId", response?.data.employeeId)
					localStorage.setItem("department", response?.data.department)
				})
			} else {
				navigate("/")
			}
		} catch (error: any) {
			let errorData = {
				errorName: error.name,
				errorServiceName: "FrontEnd Service",
				errorFunctionName: "fetchUserData function",
				source: "User_Details",
				errorDescription: "Invalid key was assigned" + error.message
			}
			await postErrorLog(errorData)
		}
	}


	useEffect(() => {

		//PS_HD-07 -PS_HD_10 - set the user logo in header.
		createLogoName();
		setviewLink(window.location.pathname);

		//PS_HD-11 - To set the session time out funtion.
		let timeoutId: any;
		const handleTimeout = () => {
			setTimeoutPopup(false);
			sessionStorage.clear();
			localStorage.clear();
			setRefreshToken(true);
		};

		const startIdleTimer = () => {
			timeoutId = setTimeout(handleTimeout, 1800000); // 30 minutes in milliseconds
		};

		startIdleTimer();

		return () => {
			clearTimeout(timeoutId);
		};

	}, [viewLink, refreshToken]);


	function createLogoName() {
		//PS_HD-12 - PS_HD_13 - CreateLogo() function helps to create the logo of the user.
		let firstName = userDetails?.firstName ?? '';
		let lastName = userDetails?.lastName ?? '';
		let logoName = firstName.substring(0, 1) + lastName.substring(0, 1);
		let upperletter = logoName.toUpperCase();
		setlogo(upperletter);
	}

	//PS_HD-18 - PS_HD_20 , PS_HD-24
	async function signOut() {
		try {
			localStorage.clear();
			navigate("/");
		} catch (error: any) {
			let errorData = {
				errorName: error.name,
				errorServiceName: "FrontEnd Service",
				errorFunctionName: "SignOut function",
				source: "Error-Log",
				errorDescription: "PostErrorData() invoke the error" + error.message
			}
			await postErrorLog(errorData)
		}
	}

	//PS_HD-20, PS_HD-25, PS_HD-26
	async function registerExitLog() {
		try {
			let userLog = {
				usermail_Id: localStorage.getItem("uniqueNameId"),
				event: "Logout",
				department: localStorage.getItem("department"),
				created_By: localStorage.getItem("displayName"),
			  };
			const response = await postLogData(userLog);

			if (response.error === 401) {
				navigate('/');
			}
			else if (response.error === 404) {
				navigate('/');
			}

			signOut();
		}
		catch (error: any) {
			let errorData = {
				errorName: error.name,
				errorServiceName: "Front-end",
				errorfunctionName: "registerExitLog",
				errorSource: "frontend",
				errorDiscription: "Invalid unregister token" + error.message
			}

			await postErrorLog(errorData)
		}
	}

	const landingNavigate = () => {
		const currentPath = window.location.pathname;
		if (currentPath === '/Landing') {
			window.location.reload();
		} else {
			navigate('/Landing')
		}
	}



	//PS_HD-22
	return (
		<>
			<nav className="navbar navbar-expand-lg navbar-light light-bg">
				<div className="container-fluid justify-content-between" >
					<a className="navbar-brand" href="#"><img src="img/ReePlee-logo.svg" onClick={() => landingNavigate()} /></a>
					<div className="dropdown custom-dropdown position-relative"  >
						<button className="btn btn-secondary d-lg-flex custom-dropdown-toggle bg-transparent border-0 color-black" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
							<span className="ms-2 profile-legend d-lg-flex align-items-center justify-content-center text-white d-none  ">
								{logo}
							</span>
							<span className="color-black mx-3 my-2 font-14 font-regular header-dropdown" title={user?.givenName}>
								{user?.givenName?.length && user?.givenName?.length > 6 ? user?.givenName?.slice(0, 6) + "..." : user?.givenName}
							</span>
						</button>
						<ul className="dropdown-menu  profile-menu text-center p-4" aria-labelledby="dropdownMenuButton1">
							<li><span className="profile-legend align-items-center justify-content-center text-white d-inline-flex">
								{logo}
							</span></li>
							<li className="font-bold my-2 py-1 font-14">{userDetails.displayName}</li>
							<li className="font-medium grey-v5 custom-dropdown-item py-1 font-12" >Employee ID: <span className="color-black font-bold">{user?.employeeId}</span></li>
							<li className="font-medium grey-v5 custom-dropdown-item py-1 font-12" >Department: <span className="color-black font-bold">{user?.department}</span></li>
							<button className="btn primary-button font-medium font-12 px-4 py-2 rounded-3 border-0 mt-2 shadow-1" onClick={() => setIsLogOut(false)}>Log Out</button>
						</ul>
					</div>
				</div>
			</nav>
			<div
				className="modal fade show"
				tabIndex={-1}
				style={
					timeoutPopup
						? { display: "none" }
						: { display: "block", backgroundColor: "rgba(0,0,0,0.6)" }
				}
				aria-modal="true"
				role="dialog"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content modal-color">
						<div className="modal-content">
							<div className="modal-header border-0 justify-content-center pt-4 pb-1">
								<img src="img/validation-error.svg" alt="error-icon" />
							</div>
							<div className="modal-body">
								<p className="text-center font-regular font-14 m-0">Your Session has been timed out due to
									inactivity.
									Please login again into the application.</p>
							</div>
							<div className="modal-footer justify-content-center border-0 gap-2 pt-0">
								<button
									hidden={false}
									type="button"
									className="btn primary-button px-4 py-2 border-0"
									data-bs-dismiss="modal"
									onClick={() => {
										setTimeoutPopup(true);
										signOut();
									}}
								>
									OK
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade show"
				tabIndex={-1}
				style={
					isLogOut
						? { display: "none" }
						: { display: "block", backgroundColor: "rgba(0,0,0,0.6)" }
				}
				aria-modal="true"
				role="dialog"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header border-0 justify-content-center pt-4 pb-1">
							<img src="img/validation-error.svg" alt="error-icon" />
						</div>
						<div className="modal-body">
							<p className="text-center font-medium font-14 m-0">Are you sure you want to Log Out ?</p>
						</div>
						<div className="modal-footer justify-content-center border-0 gap-2 pt-0">
							<button className="btn cancel-btn px-4 font-13 color-black"
								data-bs-dismiss="modal" onClick={() => { setIsLogOut(true)}}>No</button>
							<button type="button" className="btn primary-button px-4 py-2 border-0"
								data-bs-dismiss="modal" onClick={() => { registerExitLog(); signOut() }}>Yes</button>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}
export default Header
