//PC_NP_01 - PC_NP_04 Import the hooks
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { useEffect, useRef, useState } from "react";
import { postErrorLog, postProjectDetails } from "../Service/api";
import { fetchTargetFramework, uploadSonarReportFile, uploadSourceFile } from "../Service/landingApi";
import Loader from "./Loader";

//PC_NP_03 Create the NewProject function.
function NewProject() {
    const navigate = useNavigate();

    //PC_NP_02 state variable declaration.
    const [projectName, setProjectName] = useState("");
    const [convertedTo, setConvertedTo] = useState("0");
    const [sourceFile, setSourceFile] = useState("");
    const [analysisFile, setAnalysisFile] = useState("");
    const [projectNameValid, setProjectNameValid] = useState(true);
    const [convertedToValid, setConvertedToValid] = useState(true);
    const [showUndoToast, setShowUndoToast] = useState(false);
    const [initialSourceFile, setInitialSourceFile] = useState(null)
    const [initialAnalysisFile, setInitialAnalysisFile] = useState<File | null | undefined>(null)
    const [targetFramework, setTargetFramework] = useState([])
    //undo state variable.
    const [lastUndoFileName, setLastUndoFileName] = useState("")
    const [undoSourceFile, setUndoSourceFile] = useState("");
    const [lastUndoFile, setLastUndoFile] = useState(null)
    //processing popup after click the next button
    const [processingPopup, setProcessingPopup] = useState(false);
    //For error message state message.
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    //projectType state variable
    const [projectType, setProjectType] = useState("0")
    const [frameworkType, setFrameworkType] = useState<[]>([])

    const toastTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    //PC_NP_05
    const projectDetails = {
        projectName: projectName,
        convertedTo: parseInt(convertedTo),
        projectType: parseInt(projectType),
        statusId: 2,
        sourcefileName: sourceFile,
        sourcefileUrl: "",
        finalOutputFileName: "",
        finalOutputFileUrl: "",
        initialReportFileName: analysisFile,
        initialReportFileUrl: "",
        finalReportFileName: "",
        finalReportFileUrl: "",
        createdBy: localStorage.getItem("displayName"),
        modifiedBy: localStorage.getItem("displayName"),
        IsActive: true,
        playPauseStatus : "play",
        zipId : ""
    }
    
    //PC_NP_06
    useEffect(() => {
        getTargetFramework()
    }, [])

    //PC_NP_07
    async function getTargetFramework() {
        try {
            setIsLoading(true)
            let response: any = await fetchTargetFramework({});
            setIsLoading(false)
            if (response.status === 200) {
                const result = response.data;
                setTargetFramework(result.targetFrameworks);
                setFrameworkType(result.projectTypes);
                if (result.targetFrameworks.length > 0) {
                    const lastItem = result.targetFrameworks[result.targetFrameworks.length - 1];
                    setConvertedTo(lastItem.frameworkId);
                  }
            }
            else {
                navigate("/error");
            }
            return response;
        }
        //PC_NP_07 - catch block
        catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "getTargetFramework",
                errorSource: "frontend",
                errorDiscription: "Couldnt fetch Target framework drop down " + error.message
            }

            await postErrorLog(errorData)
        }
    }

    //PC_NP_07 - 10 - Check the project name with validation
    const handleProjectNameChange = (event: any) => {
        const value = event.target.value;
        handleBlur(event)
        setProjectName(value);
        if (value.trim() === "" && sourceFile !== "") {
            setProjectNameValid(false);
        }
    };

    //PC_NP_11
    const handleConvertedToChange = (event: any) => {
        const value = event.target.value;
        const index = event.target.selectedIndex
        const text = event.target[index].text;
        setConvertedTo(value);
    };

    const handleProjectType = (event: any) => {
        const value = event.target.value;
        const index = event.target.selectedIndex
        const text = event.target[index].text;
        setProjectType(value)
        setConvertedToValid(text !== "Select");
    }

    //PC_HC_12 - 13 to check the source file upload with validation.
    const fileUpload = (e: any) => {
        const file = e.target.files[0];
        setInitialSourceFile(file)

        if (!file) return;
        // Reset the error message whenever a new file is selected
        setErrorMessage("");

        if (!file.name.endsWith(".zip")) {
            setErrorMessage("Format");
            e.target.value = null;
            setSourceFile("");
            return;
        }

        if (file.size > 100 * 1024 * 1024) {
            setErrorMessage("Size");
            e.target.value = null;
            setSourceFile("");
            return;
        }

        setSourceFile(file.name);

        if (projectName.trim() === "") {
            setProjectNameValid(false);
        }
        if (projectType === "0") {
            setConvertedToValid(false);
        }
        e.target.value = null;
    };

    //PC_HC_13 - 14 - to check the analysis report file with validation.
    const analysisFileUpload = (e: any) => {
        const file = e.target.files[0];
        setInitialAnalysisFile(file)
        if (!file) return;

        setErrorMessage("");

        if (!file.name.endsWith(".pdf")) {
            setErrorMessage("pdfFormat");
            e.target.value = null;
            setAnalysisFile("")
            return;
        }

        if (file.size > 2000 * 1024) {
            setErrorMessage("pdfSize");
            e.target.value = null;
            setAnalysisFile("")
            return;
        }

        setAnalysisFile(file.name);
        e.target.value = null;
    };

    //PC_NP_24
    const handleUndo = () => {

        if (toastTimeoutRef.current) {
            clearTimeout(toastTimeoutRef.current);
            toastTimeoutRef.current = null;
        }

        if (lastUndoFileName.endsWith(".zip")) {
            setSourceFile(undoSourceFile); // Restore sourceFile if it was a .zip file
            setInitialSourceFile(lastUndoFile) 
        }
        if (projectName.trim() === "") {
            setProjectNameValid(false);
        }
        if (projectType === "0") {
            setConvertedToValid(false);
        }

        setShowUndoToast(false); // Hide the toast message
    };


    //PC_HC_16 - PC_HC_20 -- To check the all validation and send the data with processing popup message.
    const handleNextClick = async (projectInfo: any) => {
        setIsLoading(true)
        const projectsInformation = JSON.stringify(projectInfo);
        let result = await postProjectDetails(projectsInformation);


        if (initialSourceFile) {
            await uploadSourceFile(initialSourceFile, result?.data)
        }
        if (initialAnalysisFile) {
            await uploadSonarReportFile(initialAnalysisFile as File, result?.data);
        }

        setIsLoading(false)
        setProcessingPopup(!processingPopup);
    };



    //PC_NP_08
    const blockInvalidChar = (e: any) => {
        const regex = /^[a-zA-Z0-9_-]*$/;
        if (!regex.test(e.key)) {
            e.preventDefault();
        }
    };

    //PC_NP_10
    const handleBlur = (e: any) => {
        if (e.target.value.startsWith('_') || e.target.value.startsWith('-')) {
            setProjectNameValid(false)
        } else {
            setProjectNameValid(true)
        }
    };

    //PC_NP_21
    const removeSourceFile = () => {


        setUndoSourceFile(sourceFile);
        setLastUndoFileName(sourceFile)
        setLastUndoFile(initialSourceFile) //file will stored in the lastUndoFile state variable.
        setInitialSourceFile(null)
        setSourceFile("");
        setProjectNameValid(true)
        setConvertedToValid(true)
        setShowUndoToast(true);

        if (toastTimeoutRef.current) {
            clearTimeout(toastTimeoutRef.current);
        }

        toastTimeoutRef.current = setTimeout(() => {
        setShowUndoToast(false);
        toastTimeoutRef.current = null;  // Reset the ref after the timeout completes
    }, 4000);

    };

    //PC_NP_22
    const removeAnalysisFile = () => {
        setInitialAnalysisFile(null)
        setAnalysisFile("");
    };

    //PC_NP_26
    const nextOkButton = () => {
        navigate('/Landing')
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text');
        const validPastedText = pastedText.replace(/[^a-zA-Z0-9_-]/g, '');
        const newValue = projectName + validPastedText;
        setProjectName(newValue.slice(0, 30));
    };


    return (

        <>
            {isLoading ? <Loader isLoading={isLoading} /> :
                <>
                    <Header />

                    <div className="parent-container p-5">
                        <div className="project-container bg-white custom-border p-5">
                            <div className="d-flex align-items-center w-50 ">
                                <a href="/Landing" className="back-arrow"><img src="./img/left-arrow.svg" alt="left-arrow" /></a>
                                <h4 className="font-20 font-bold mb-0 ms-2">New Project</h4>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="p-4 h-100 w-50 my-3">
                                    <div className="progress-container mt-4">
                                        <div className="progress-step active">
                                            <div className="step-icon active"></div>
                                            <div className="step-label font-bold font-12">Upload</div>
                                        </div>
                                        <div className="progress-step">
                                            <div className="step-icon"></div>
                                            <div className="step-label font-medium font-12 grey-v4">Output</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <h4 className="font-18 font-bold custom-margin-left">Project Information</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4 my-3 px-4">
                                                <label className="form-label font-14 font-medium" htmlFor="ProjectName">Project Name <span className="color-red">*</span></label>
                                                <input
                                                    type="text"
                                                    className={projectNameValid ? "form-control floating-lab font-regular font-14 custom-border" : "form-control floating-lab font-regular font-14 custom-border error"}
                                                    placeholder="Enter the Project Name"
                                                    value={projectName}
                                                    onChange={handleProjectNameChange}
                                                    onKeyDown={(e) => {
                                                        blockInvalidChar(e);
                                                    }}
                                                    autoComplete="off"
                                                    maxLength={30}
                                                    onPaste={handlePaste}
                                                />
                                            </div>
                                            <div className="col-md-4 my-3 px-4">
                                                <label className="form-label font-14 font-medium" htmlFor="ConvertTo">Convert To <span className="color-red">*</span></label>
                                                <select
                                                    className="form-select custom-border "
                                                    aria-label="Default select example"
                                                    value={convertedTo}
                                                    onChange={handleConvertedToChange}
                                                >
                                                    <option value={0} disabled>Select</option>
                                                    {targetFramework && targetFramework.length > 0 && targetFramework.map((value: any) => (
                                                        <option key={value.frameworkId} value={value.frameworkId}>{value.frameworkName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-4 my-3 px-4">
                                                <label className="form-label font-14 font-medium" htmlFor="ConvertTo">Project Type <span className="color-red">*</span></label>
                                                <select
                                                    className={convertedToValid ? "form-select custom-border " : "form-select custom-border error"}
                                                    aria-label="Default select example"
                                                    value={projectType}
                                                    onChange={handleProjectType}
                                                >
                                                    <option value={0} disabled>Select</option>
                                                    {frameworkType && frameworkType.length > 0 && frameworkType.map((value: any) => (
                                                        <option key={value.projectTypeId} value={value.projectTypeId}>{value.projectTypeName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-4 my-3 px-4">
                                                <label className="form-label font-14 font-medium">Upload File <span className="color-red">*</span></label>
                                                <span className="upload-btn-wrapper d-block cursor-pointer" >
                                                    <button type="button" className="d-flex align-items-center justify-content-center upload-btn-sty bg-white shadow-none cursor-pointer text-center py-2 custom-upload-btn-height">
                                                        <img src="./img/upload.svg" alt="browse" />
                                                        <span className="font-medium font-13 d-block grey-v3 ms-2">Upload or drag and drop file here</span>
                                                    </button>
                                                    <input disabled={initialSourceFile !== null && sourceFile !== ""} type="file" name="myfile" id="FileUpload" className="cursor-pointer cust-file" onChange={fileUpload} />
                                                </span>
                                                <div className="uploaded-content my-2 px-2 py-1" hidden={!sourceFile}>
                                                    <span className="font-medium font-14 d-flex align-items-center" >{sourceFile}
                                                        <img src="img/close-icon.svg" alt="close-icon" className="cursor-pointer ms-2" onClick={() => removeSourceFile()}/>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 my-3 px-4">
                                                <label className="form-label font-14 font-medium">Upload Analysis Report</label>
                                                <span className="upload-btn-wrapper d-block cursor-pointer">
                                                    <button type="button" className="d-flex align-items-center justify-content-center upload-btn-sty bg-white shadow-none cursor-pointer text-center py-2 custom-upload-btn-height">
                                                        <img src="./img/upload.svg" alt="browse" />
                                                        <span className="font-medium font-13 d-block grey-v3 ms-2">Upload or drag and drop file here</span>
                                                    </button>
                                                    <input disabled={initialAnalysisFile != null} type="file" name="myfile" className="cursor-pointer cust-file" onChange={analysisFileUpload} />
                                                </span>
                                                <div className="uploaded-content my-2 px-2 py-1" hidden={!analysisFile}>
                                                    <span className="font-medium font-14 d-flex align-items-center" >{analysisFile}
                                                        <img src="img/close-icon.svg" alt="close-ico" className="cursor-pointer ms-2" onClick={() => removeAnalysisFile()}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end align-items-center mt-5 mb-3 px-3">
                                            <button
                                                className="btn primary-button next-btn font-medium font-13 d-flex gap-2 justify-content-center align-items-center rounded-3 border-0"
                                                style={{ width: '9%', padding: '0.7%' }}
                                                disabled={!(projectName.trim() !== '' && convertedTo !== "0" && sourceFile !== '' && projectType !== "0")}
                                                onClick={() => {
                                                    handleNextClick(projectDetails);
                                                }}>
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showUndoToast && (
                        <div className="modal fade show" id="staticBackdrop" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ display: 'block' }}>
                            <div className="d-flex justify-content-center" onClick={() => handleUndo()}>
                                <div className="custom-toast-undo d-flex align-items-center gap-3 m-4 shadow-sm px-3 py-2">
                                    <button type="button" className="btn primary-button border-0 p-2 cursor-pointer"><img
                                        src="img/undo-icon.svg" alt="undo-icon" /></button>
                                    <p className="font-16 font-bold color-black m-0">
                                        <span className="d-block">Undo</span>
                                        <span className="m-0 font-14 font-medium">File {lastUndoFileName} has been removed</span>
                                    </p>

                                </div>
                            </div>
                        </div>
                    )}
                    {/* PC_NP_18 */}
                    {processingPopup && (
                        <div className="modal fade show" tabIndex={-1} style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }} aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0 justify-content-center pt-4 pb-1">
                                        <img src="img/ReePlee-logo.svg" alt="error-icon" />
                                    </div>
                                    <div className="modal-body">
                                        <p className="text-center font-bold font-14 m-0">Conversion in Progress...</p>
                                        <p className="text-center font-12 font-medium grey-v1">Note: This process might take a bit longer than anticipated. Please click the
                                            OK button, and we'll continue the conversion in the background.</p>
                                    </div>
                                    <div className="modal-footer justify-content-center border-0 gap-2 pt-0">

                                        <button type="button" className="btn primary-button px-4 py-2 border-0"
                                            data-bs-dismiss="modal" onClick={() => nextOkButton()}>Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {errorMessage && (
                        <div className="error-message">
                            {errorMessage === "Size" ? (
                                <div className="modal fade show" tabIndex={-1} style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }} aria-modal="true" role="dialog">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header border-0 justify-content-center pt-4 pb-1">
                                                <img src="img/warning-icon.svg" alt="error-icon" />
                                            </div>
                                            <div className="modal-body">
                                                <p className="text-center font-regular font-14 m-0">File limit exceeds. Upload a file below 100 MB.</p>

                                            </div>
                                            <div className="modal-footer justify-content-center border-0 pt-0">
                                                <button type="button" className="btn primary-button px-4 py-2 border-0"
                                                    data-bs-dismiss="modal" onClick={() => { setSourceFile(""); setErrorMessage(""); setInitialSourceFile(null) }}>Ok</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : errorMessage === "Format" ?
                                (<div className="modal fade show" tabIndex={-1} style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }} aria-modal="true" role="dialog">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header border-0 justify-content-center pt-4 pb-1">
                                                <img src="img/warning-icon.svg" alt="error-icon" />
                                            </div>
                                            <div className="modal-body">
                                                <p className="text-center font-regular font-14 m-0">The file you have given is not in a valid format. Please proceed with the valid  ZIP format for the conversion.</p>

                                            </div>
                                            <div className="modal-footer justify-content-center border-0 pt-0">
                                                <button type="button" className="btn primary-button px-4 py-2 border-0"
                                                    data-bs-dismiss="modal" onClick={() => { setSourceFile(""); setErrorMessage(""); setInitialSourceFile(null) }}>Ok</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ) : errorMessage === "pdfFormat" ? (
                                    <div className="modal fade show" tabIndex={-1} style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }} aria-modal="true" role="dialog">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header border-0 justify-content-center pt-4 pb-1">
                                                    <img src="img/warning-icon.svg" alt="error-icon" />
                                                </div>
                                                <div className="modal-body text-center">
                                                    <p className="text-center font-regular font-14 m-0"></p>
                                                    The uploaded file is not in a valid format. Please upload a PDF file.
                                                </div>
                                                <div className="modal-footer justify-content-center border-0 pt-0">
                                                    <button type="button" className="btn primary-button px-4 py-2 border-0"
                                                        data-bs-dismiss="modal" onClick={() => { setAnalysisFile(""); setErrorMessage(""); setInitialAnalysisFile(null) }}>Ok</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                    : errorMessage === "pdfSize" ? (
                                        <div className="modal fade show" tabIndex={-1} style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }} aria-modal="true" role="dialog">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0 justify-content-center pt-4 pb-1">
                                                        <img src="img/warning-icon.svg" alt="error-icon" />
                                                    </div>
                                                    <div className="modal-body">
                                                        <p className="text-center font-regular font-14 m-0 color-black">File limit exceeds. Upload a file below 2000 KB.</p>

                                                    </div>
                                                    <div className="modal-footer justify-content-center border-0 pt-0">
                                                        <button type="button" className="btn primary-button px-4 py-2 border-0"
                                                            data-bs-dismiss="modal" onClick={() => { setAnalysisFile(""); setErrorMessage(""); setInitialAnalysisFile(null) }}>Ok</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                        : null}</div>
                    )}

                </>
            }

        </>
    );
}
export default NewProject;
