import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Components/Login';
import LoginAuth from './Components/LoginAuth';
import NewProject from './Components/NewProject';
import LandingPageNew from './Components/LandingPageNew';
import ProjectComponent from './Components/OutputComponent';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/reepleelogin' element={<LoginAuth />} />
          <Route path='/NewProject' element={<NewProject />} />
          <Route path="/Landing" element={<LandingPageNew />} />
          <Route path="/Output" element={<ProjectComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
