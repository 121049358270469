import axios, { AxiosHeaders } from "axios";
import { postErrorLog } from "../Service/api";

export interface ClientOptions {
    headers?: AxiosHeaders
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
}

export const client = async (method: string, endpoint: string, payload?: any, options?: ClientOptions) => {
    try {

        let url = `${process.env.REACT_APP_BASE_URL}/${endpoint}`

        const requestConfigurations = {
            method: method,
            url: url,
            headers: options?.headers || {
                "Content-Type": process.env.REACT_APP_CONTENT_TYPE,
                "Authorization": localStorage.getItem("token"),
                "X-CSRF-Token": localStorage.getItem("csrfToken")
            },
            data: payload,
            responseType: options?.responseType || 'json'
        }

        const response = await axios(requestConfigurations);
        return response;

    } catch (error: any) {
        let errorData = {
            errorName: error.name,
            errorServiceName: "Front-end",
            errorfunctionName: "client",
            errorSource: "frontend-client.ts",
            errorDiscription: "Couldnt fetch Status drop down" + error.message
        }
        await postErrorLog(errorData)
    }
}

