import { AxiosHeaders } from "axios";
import { ClientOptions, client } from "./landingClient";


//PC_18-PC_22
export async function getActivityLog(payload: any) {
  let result = await client("POST", "projects", payload);
  return result
}

//PC_111-PC_113
export async function fetchTargetFramework(payload: any) {
  let result = await client("POST", "dropdown", payload);
  return result
}

//PC_121-PC_124
export async function fetchStatus(payload: any) {
  let result = await client("POST", "dropdown", payload);
  return result
}

//PC_85-PC_97
export async function uploadSonarReportFile(file: File, projectId: any) {

  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("projectId", projectId);

  const headers = new AxiosHeaders();
  headers.setContentType('multipart/form-data');
  headers.setAuthorization(localStorage.getItem("token"));

  const csrfToken = localStorage.getItem("csrfToken"); // Or however you're storing it
  headers.set("X-CSRF-Token", csrfToken);

  const clientOptions: ClientOptions = {
    headers: headers
  }


  let result = await client("POST", `uploadfile/${projectId}`, formData, clientOptions);
  return result;
}

//PC_27-PC_28
export async function uploadFinalSonarReportFile(file: File,projectId:any) {

  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("projectId", projectId);

  const headers = new AxiosHeaders();
  headers.setContentType('multipart/form-data');
  headers.setAuthorization(localStorage.getItem("token"))

  const csrfToken = localStorage.getItem("csrfToken"); // Or however you're storing it
  headers.set("X-CSRF-Token", csrfToken);

  const clientOptions: ClientOptions = {
    headers: headers
    
  }
  

  let result = await client("POST", `uploadFinalSonarReport/${projectId}`, formData,clientOptions);
  return result;
}

//PC_NP_33.1 - PC_NP_33.8
export async function uploadSourceFile(file: File, projectId: any) {

  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("projectId", projectId);

  const headers = new AxiosHeaders();
  headers.setContentType('multipart/form-data');
  headers.setAuthorization(localStorage.getItem("token"));

  const csrfToken = localStorage.getItem("csrfToken"); // Or however you're storing it
  headers.set("X-CSRF-Token", csrfToken);

  const clientOptions: ClientOptions = {
    headers: headers
  }


  let result = await client("POST", `uploadSourceZipFile/${projectId}`, formData, clientOptions);
  return result;
}


//PC_131-PC_132
export async function downloadSonarReportFile(projectId: any) {
  let result = await client("GET", `downloadReportfile/${projectId}`, null, { responseType: 'blob' });
  return result
}

//PC_OC_29-PC_OC_30
export async function downloadFinalSonarReportFile(projectId:any) {
  let result = await client("GET", `downloadFinalReportfile/${projectId}`,null, {responseType: 'blob'});
  return result
}


//PC_152-PC_153
export async function fetchdownloadConvertedFile(projectId: any) {
  let result = await client("GET", `downloadZipfile/${projectId}`, null, { responseType: 'arraybuffer' });
  return result
}


//PC_OC_87-PC_OC_88
export async function previewConvertedFile(projectId: number) {
  let result = await client("GET", `ExtractAndPreview/${projectId}`, {});
  return result
}

//PC_OC_31-PC_OC_35
export async function deleteInitialSonarReport(projectId: number) {
  let result = await client("DELETE", `deleteInitialSonar/${projectId}`, {});
  return result
}

//PC_OC_36-PC_OC_38
export async function deleteFinalSonarReport(projectId: number) {
  let result = await client("DELETE", `deleteFinalSonar/${projectId}`, {});
  return result
}

//PC_242 - PC_245
export async function handleRetryButton(retryPayload: {
    projectId: string;
    targetFramework: any;
    projectType: any;
    playPauseStatus: string;
    zipId: string;
}) {
    let result = await client("POST", "retry", retryPayload);
    return result;
}

//PC_264 - PC_268
export async function handlePlayPauseButton(playPausePayload: { 
  projectId: string; 
  targetFramework: any; 
  projectType: any; 
  playPauseStatus: string; 
  zipId: string 
}){
  let result = await client("POST", "playPause", playPausePayload)
  return result
}

//PC_251-PC_253
export async function fetchProjectType(payload: any) {
  let result = await client("POST", "dropdown", payload);
  return result
}