
import { useEffect, useState } from "react";
import { getLogin, postLogData, postErrorLog } from "../Service/api";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

//PC_LC_11 - PC_LC_16 - create LoginAuth function and state variable for loading and get the payload = "code".
function LoginAuth() {

  const search = window.location.search;

  //PC_LC_17 - Initialize the code value.
  let code = new URLSearchParams(search).get("code") || "";

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  //PC_LC_18 - PC_LC_21 - useEffect trigger and get the userLogin detail.
  useEffect(() => {
    let handleError = new URLSearchParams(search).get("error");
    localStorage.clear();
    if (handleError != null) navigate("/");
    getDetails();
  },[]);

  //PC_LC_48 - PC_LC_51 - get the detail and post the user detail in the database.
  async function postUserLogDetails(userLog: any) {
    try {
      const response = await postLogData(userLog);
      if(response.error === 401) {
        navigate('/');
      }
      else if(response.error === 404) {
        navigate('/');
      }
    }
     //Refer PC_LC_64 - If error occurs, navigate to the error page and trigger the postErrorLog() function insert the error data.
    catch (error : any) {
      let errorData = {
        errorName: error.name,
        errorServiceName: "FrontEnd Service",
        errorFunctionName: "GetUserLogin function",
        source: "User-Log",
        errorDescription: "PostLogData() invoke the error" + error.message
      }
      await postErrorLog(errorData)
    }
  }

  //PC_LC_19 - PC_LC_25 - Invoke the function and store the data in the local storage. and get the user details.
  const getDetails = async () => {
    try {

      setIsLoading(true);
      const loginResponse = await getLogin(code);
      setIsLoading(false);

      //PS_LC_49
      let userLog = {
        usermail_Id: loginResponse?.data?.Claims?.unique_name,
        event: "Login",
        department: localStorage.getItem("department"),
        created_By: loginResponse?.data?.Claims?.name,
      };
      localStorage.setItem("csrfToken", loginResponse.data.csrfToken)
      localStorage.setItem("emailId", loginResponse?.data.Claims.upn);
      localStorage.setItem("uniqueNameId", loginResponse?.data.Claims.unique_name);
      localStorage.setItem("displayName", loginResponse?.data.Claims.name);
      localStorage.setItem("firstName", loginResponse?.data.Claims.given_name);
      localStorage.setItem("lastName", loginResponse?.data.Claims.family_name);
      localStorage.setItem("token", "Bearer " + loginResponse?.data.token);
      localStorage.setItem("refreshToken", loginResponse?.data.refreshToken);
      localStorage.setItem("rfExpiry", loginResponse?.data.expiresOn);

      postUserLogDetails(userLog);
      redirectURI();
    }
    //PC_LC_64 - If error occurs, navigate to the error page and trigger the postErrorLog() function insert the error data.
    catch (error :any) {
      let errorData = {
        errorName: error.name,
        errorServiceName: "FrontEnd service",
        errorFunctionName: "GetUserLogin() function",
        source: "Get User Details",
        errorDescription: "Can't able to get the user details" + error.message
      }
      await postErrorLog(errorData)
      navigate("/");
    }

  };

  //PS_LC_63
  let redirectURI = () => {
    navigate("/Landing");
  };

  return (
    <>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default LoginAuth;
