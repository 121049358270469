import React from "react";


//PC_LC_01 - PC_LC_06 - Declare the Login function and invoke the codeConverterLogin().
function Login(){
    const tenantId = process.env.REACT_APP_TENANT_ID
    const clientId = process.env.REACT_APP_CLIENT_ID

    //PC_LC_06 - PC_LC_10 - codeConverterLogin() function return the login html and get the windows.url.pathname.
    const codeConverterLogin = async () => {
        window.location.href = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${window.location.origin}/reepleelogin&scope=https://graph.microsoft.com/.default&prompt=select_account`;
    };
    return(      
        <div  className="container-fluid">
        <div  className="row ">
          <div  className=" col-xl-6 d-xl-block d-none px-0">
            <div  className="login-bg-height position-relative my-0">
              <div  className="login-bg-height login-bg-img">
                <div  className="d-flex flex-column justify-content-end align-items-center login-bg-height">
                  <div  className="mb-4 text-center"> 
                    <img src="img/login-bg.svg" alt="login-bg" />                   
                  <p  className="font-48 font-regular color-white">Welcome to <span  className="font-bold">Reeplee</span></p>
                  <p  className="font-22 font-bold color-white">The new way to provision and <span  className="d-block">manage your Code Details</span></p>
                  <p  className="font-22 font-bold color-white mb-4">Convert your .NET Solution simple & better</p>
                </div>
                </div>
              </div>
            </div>
          </div>  
          <div  className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center">
            <div  className="row justify-content-center">
              <div  className="col-md-12 px-4 pt-4">
              </div>
              <div  className="col-md-11 col-sm-12 pt-0 pb-0">
                <div  className="login-content-height w-100 d-flex">
                  <div  className="form-signup my-auto w-100 text-center">
                    <h5  className=" font-34 primary-textcolor font-bold d-flex justify-content-center"><img src="img/logo-icon.svg" alt="reeplee-logo"  className="me-2" />Reeplee</h5>
                    <p  className="mb-4  primary-textcolor font-medium grey-v1 font-18">Please Login to Your Account</p>
                    <div  className="d-md-flex d-block gap-4 mb-5 align-items-center justify-content-center">
                      <button type="button"  className="primary-button mb-3 px-5 border-0 border-size-5 font-bold font-16 text-white py-2 w-295"
                      onClick={()=>
                        {codeConverterLogin()}}
                      >Login</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>    
          </div>  
        </div>
      </div>        
    );
}
export default Login