import { EncryptStorage } from 'encrypt-storage';


const REACT_APP_SECRET_NAME = "codeConverter";

export const encryptStorage = new EncryptStorage(REACT_APP_SECRET_NAME, {
     stateManagementUse: true,
     storageType: 'localStorage',
});

export const loginCredientials = {
     tenantId: process.env.REACT_APP_TENANT_ID,
     clientId: process.env.REACT_APP_CLIENT_ID,
};

export const appURL = process.env.REACT_APP_API_URL;
                                   
export const domain = {
     userService: "http://localhost:8080",
}

export const duration_ms =  parseInt(process.env.REACT_APP_DURATION_MS!);
   
