interface loader {
    isLoading: boolean;
}

export default function Loader({ isLoading }: loader) {
    return (
      <>
        <div className="container-fluid" hidden={!isLoading}>
        <div className="d-flex flex-column loader-container align-items-center justify-content-center">
            <img src="img/loader.gif" className="loader-gif" />
            <p className="font-32 font-bold grey-v5 fst-italic mt-3">Loading...</p>
        </div>          
        </div>
      </>
    );
  }