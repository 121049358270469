import axios, { Method } from "axios";


//PC_LC_57 - PC_LC_61 - function send the data to the backend and get the response.
export async function client(endpoint: string, body: any, requestType: Method) {
  
  try {
    
     let url = `${process.env.REACT_APP_BASE_URL}/${endpoint}`

    let requestConfig: any = {
      method: requestType,
      url: url, 
      data: body, 
      headers: {
        "Content-Type": process.env.REACT_APP_CONTENT_TYPE,
        "Authorization": localStorage.getItem("token"),
        "X-CSRF-Token": localStorage.getItem("csrfToken")
      }
    }
    const response = await axios(requestConfig);
    return { statusCode: response.status, data: response?.data}
  } catch (error: any) {
    return { error: error.request.status, data:error?.response?.data }
  }
}
