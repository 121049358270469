import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { fetchAgentStatus, postErrorLog } from "../Service/api";
import { uploadSonarReportFile, downloadSonarReportFile, fetchdownloadConvertedFile, fetchStatus, fetchTargetFramework, getActivityLog, uploadFinalSonarReportFile, downloadFinalSonarReportFile, deleteInitialSonarReport, deleteFinalSonarReport, handleRetryButton, fetchProjectType, handlePlayPauseButton } from '../Service/landingApi';
import moment from "moment";
import Header from './Header';
import Loader from './Loader';
import { duration_ms } from '../constants/constant';


interface Project {
    projectId: number;
    projectName: string;
    targetFramework: string;
    statusId: string;
    sourceFile: string;
    sourceFileName: string;
    finalOutputFile: string;
    finalOutputFileName: string;
    initialSonarReport: string;
    initialSonarReportName: string;
    finalSonarReport: string;
    finalSonarReportFileName: string;
    createdUserId: number;
    createdAt: string;
    createdBy: string;
    modifiedBy: string;
    modifiedAt: string;
    isActive: boolean;
    orcAgentStatus: string;
    winAgentStatus: string;
    conAgentStatus: string;
    valAgentStatus: string;
    testAgentStatus: string;
    debuggerAgentStatus: string;
    executorAgentStatus: string;
    durationMinutes: string;
    projectType: string;
    retryTime: string;
    playPauseStatus: string;
    zipId: string;
    agentIteration: number;
    pausePlayDuration: number;
}

interface sortSearchObj {
    sort: string;
    sortOrder: string;
    search: string;
    filterData: filterObj;
    loadCount: number;
}

interface filterObj {
    startDate: string,
    endDate: string,
    targetFramework: string,
    status: number,
    projectType: string
}

//PC_04 - PC_15 :State variable declarations

function LandingPageNew() {

    let filterObj: filterObj = {
        startDate: "",
        endDate: "",
        targetFramework: "",
        status: 0,
        projectType: ""
    };
    const staticStartDate = "2024-01-01";
    const [enableFilterButton, setEnnableFilterButton] = useState<boolean>(true);

    const [myActivityDatas, setMyActivityDatas] = useState<Project[]>([]);
    const [totalActivityLogCount, setTotalActivityLogCount] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [sort, setSort] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<string>("desc");
    const [filterData, setFilterData] = useState<filterObj>(filterObj);
    const [loadCount, setLoadCount] = useState<number>(10);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [targetFramework, setTargetFramework] = useState<[]>([])
    const [status, setStatus] = useState<string[]>([])
    const [projectType, setProjectType] = useState<[]>([])

    const [showReportButtonModal, setShowReportButtonModal] = useState<boolean>(false);
    const [initialReportFile, setInitialReportFile] = useState("");
    const [finalReportFile, setFinalReportFile] = useState("");
    const [showReportSuccessToast, setShowReportSuccessToast] = useState(false);
    const [isFileHidden, setIsFileHidden] = useState({ initial: false, final: false });
    const [showDownloadSuccessToast, setShowDownloadSuccessToast] = useState(false);
    const [showDownloadErrorToast, setShowDownloadErrorToast] = useState(false)
    const [sonarFile, setSonarFile] = useState<File>();
    const [resetKey, setResetKey] = useState(Date.now());
    const [selectedReport, setSelectedReport] = useState<any>(null);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isInitialReportSubmitted, setIsInitialReportSubmitted] = useState(false);
    const navigate = useNavigate();
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);
    const [noRecordsMsg, setNoRecordsMsg] = useState("No Records Found");
    const [isSubmitClickedFinal, setIsSubmitClickedFinal] = useState(false)
    const [activeTab, setActiveTab] = useState('InitialReport');
    const [noRecordsHtml, setNoRecordsHtml] = useState<JSX.Element | null>(null);
    const intervalIdRef = useRef<NodeJS.Timer | null>(null);


    //PC_16:  UseEffect call getPreviousData on page onload
    useEffect(() => {
        getPreviousData();
    }, [loadCount, sort, sortOrder])

    useEffect(() => {
        if (search.trim() == "") {
            setNoRecordsMsg("No Search Record Found");
        }
    }, [search])

    useEffect(() => {
        intervalIdRef.current = setInterval(() => {
            getAgentStatus()
        }, 5000);

        return () => {
            stopInterval()
        };
    }, []);

    useEffect(() => {
        setIsLoading(true)
        handleClear()
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
    }, [])

    const stopInterval = () => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
    };

    //PC_244-PC_245
    const isRetryButtonDisabled = (retryTime: string | null) => {
        if (!retryTime) return false;

        const formattedRetryTime = moment(retryTime, "YYYY-MM-DD HH:mm:ss.SSSSSS").format("L LT");

        const retryTimestamp = moment(formattedRetryTime, "L LT").valueOf();
        const currentTime = Date.now();

        const utc = new Date(currentTime).toISOString();
        const formattedUtc = moment(utc, "YYYY-MM-DD HH:mm:ss.SSSSSS").format("L LT")
        const utcTimestamp = moment(formattedUtc, "L LT").valueOf();

        const timeDifference = utcTimestamp - retryTimestamp;

        return timeDifference < duration_ms;
    };

    //PC_235-PC_241
    const handleRetryClick = async (projectInfo: any) => {
        const projectId = String(projectInfo.projectId);
        try {
            const retryPayload = {
                projectId: projectId,
                targetFramework: projectInfo.targetFramework,
                projectType: projectInfo.projectType,
                playPauseStatus: projectInfo.playPauseStatus,
                zipId: ""
            };
            let result = await handleRetryButton(retryPayload);

            if (result?.status === 200) {
                getPreviousData()
                setTimeout(() => {
                    getPreviousData();
                }, duration_ms);
            } else {

                console.error('Retry was not successful:', result?.data?.message);
            }
        } catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "handleRetryButton",
                errorSource: "frontend",
                errorDiscription: "Retry failed" + error.message
            }
            await postErrorLog(errorData)

        }
    };

    //PC_256 - PC_263
    const handlePlayPauseBtn = async (projectInfo: any) => {
        const projectId = String(projectInfo.projectId);
        const currentStatus = projectInfo.playPauseStatus; // Assume this property exists

        try {
            const playPausePayload = {
                projectId: projectId,
                targetFramework: projectInfo.targetFramework,
                projectType: projectInfo.projectType,
                playPauseStatus: currentStatus === 'play' ? 'pause' : 'play',
                zipId: projectInfo.zipId  // Toggle the status
            };
            let result = await handlePlayPauseButton(playPausePayload);

            if (result?.status === 200) {
                // Update the myActivityData to reflect the new play/pause status
                setMyActivityDatas(prevData =>
                    prevData.map(project =>
                        project.projectId === parseInt(projectId)
                            ? { ...project, playPauseStatus: playPausePayload.playPauseStatus }
                            : project
                    )
                );
            } else {
                console.error('Play/Pause action was not successful:', result?.data?.message);
            }
        } catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "handlePlayPauseButton",
                errorSource: "frontend",
                errorDiscription: "Play/Pause action failed: " + error.message
            }
            await postErrorLog(errorData);
        }
    };

    //PC_129-PC_148
    async function downloadSonarReport() {
        try {
            const projectIdStr = selectedReport.projectId.toString();
            let result = await downloadSonarReportFile(projectIdStr);
            if (result && result.data) {
                var blob = new Blob([result.data], { type: 'application/pdf' });
                var url = window.URL.createObjectURL(blob);

                if (downloadLinkRef.current) {
                    downloadLinkRef.current.href = url;
                    downloadLinkRef.current.download = initialReportFile;
                    downloadLinkRef.current.click();

                    window.URL.revokeObjectURL(url);
                }
                closeModal()
                setShowDownloadSuccessToast(true);
                setTimeout(() => setShowDownloadSuccessToast(false), 3000);
            } else {
                setError('download failed');
                setShowDownloadErrorToast(true);
                setTimeout(() => setShowDownloadErrorToast(false), 3000);
            }
        }

        catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "downloadSonarReport",
                errorSource: "frontend",
                errorDiscription: "Report Download failed" + error.message
            }
            setShowDownloadErrorToast(true);
            setTimeout(() => setShowDownloadErrorToast(false), 3000);
            await postErrorLog(errorData)
        }
    }

    //PC_198-PC_199
    async function downloadFinalSonarReport() {
        try {
            const projectIdStr = selectedReport.projectId.toString();
            let result = await downloadFinalSonarReportFile(projectIdStr);
            if (result && result.data) {
                var blob = new Blob([result.data], { type: 'application/pdf' });
                var url = window.URL.createObjectURL(blob);

                if (downloadLinkRef.current) {
                    downloadLinkRef.current.href = url;
                    downloadLinkRef.current.download = initialReportFile;
                    downloadLinkRef.current.click();
                    window.URL.revokeObjectURL(url);
                }
                closeModal()
                setShowDownloadSuccessToast(true);
                setTimeout(() => setShowDownloadSuccessToast(false), 3000);
            } else {
                setError('download failed');
                setShowDownloadErrorToast(true);
                setTimeout(() => setShowDownloadErrorToast(false), 3000);
            }
        }

        catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "downloadFinalSonarReport",
                errorSource: "frontend",
                errorDiscription: "Report Download failed" + error.message
            }
            setShowDownloadErrorToast(true);
            setTimeout(() => setShowDownloadErrorToast(false), 3000);
            await postErrorLog(errorData)
        }
    }



    //PC_149-PC_162
    const downloadConvertedFile = async (project: any) => {
        try {
            setIsLoading(true)
            let result = await fetchdownloadConvertedFile(project.projectId);
            setIsLoading(false)
            if (result && result.data) {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(new Blob([result.data], { type: 'application/zip' }));
                link.setAttribute('download', project.projectName + ".zip");

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setShowDownloadSuccessToast(true);
                setTimeout(() => setShowDownloadSuccessToast(false), 3000); // Hide toast after 3 seconds
            } else {
                setShowDownloadErrorToast(true);
                setTimeout(() => setShowDownloadErrorToast(false), 3000); // Hide toast after 3 seconds
            }
        } catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "downloadConvertedFile",
                errorSource: "frontend",
                errorDiscription: "Converted file Download failed" + error.message
            }

            await postErrorLog(errorData);
        }
    };


    //PC_185-PC_190
    async function removeInitialReport() {
        try {
            const projectIdStr = selectedReport.projectId.toString();
            let result: any = await deleteInitialSonarReport(projectIdStr);
            if (result && result.status === 204) {
                setInitialReportFile("");
                setIsSubmitClicked(false)
                setIsInitialReportSubmitted(false);
                getPreviousData()

            }
            else {
                setError('file couldnt be removed');
            }

        } catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "removeInitialReport",
                errorSource: "frontend",
                errorDiscription: "Initial report removal failed" + error.message
            }
            await postErrorLog(errorData)
        }
    }

    //PC_191-PC_197
    async function removeFinalReport() {
        try {
            const projectIdStr = selectedReport.projectId.toString();
            let result: any = await deleteFinalSonarReport(projectIdStr);
            if (result && result.status === 204) {
                setFinalReportFile("")
                setIsSubmitClickedFinal(false)
                getPreviousData()
            }
            else {
                setError('file couldnt be removed');
            }

        } catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "removeFinallReport",
                errorSource: "frontend",
                errorDiscription: "Final report removal failed" + error.message
            }
            await postErrorLog(errorData)
        }
    }

    //PC_200-PC_201
    async function uploadFinalSonarReport() {
        try {

            if (sonarFile === undefined) {
                return
            }
            if (!selectedReport) {
                return;
            }

            const projectIdStr = selectedReport.projectId.toString();

            let result: any = await uploadFinalSonarReportFile(sonarFile, projectIdStr);

            if (result && result.status === 200) {
                setSonarFile(undefined)
                setShowReportButtonModal(false);
                setShowReportSuccessToast(true);


                setTimeout(() => setShowReportSuccessToast(false), 3000); // Hide toast after 3 seconds
                getPreviousData()
                setFinalReportFile("")
                setIsInitialReportSubmitted(true)
                setIsSubmitClickedFinal(true);
                return result.data;

            } else {
                setError('Upload Failed');
                setFinalReportFile("")
            }
        } catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "uploadFinalSonarReport",
                errorSource: "frontend",
                errorDiscription: "Upload failed" + error.message
            }
            await postErrorLog(errorData)
        }
    }

    //PC_82-PC_103
    async function uploadSonarReport() {
        try {

            if (sonarFile === undefined) {
                return
            }
            if (!selectedReport) {
                return;
            }

            const projectIdStr = selectedReport?.projectId.toString();


            let result: any = await uploadSonarReportFile(sonarFile, projectIdStr);

            if (result && result.status === 200) {
                setSonarFile(undefined)
                setShowReportButtonModal(false);
                setShowReportSuccessToast(true);
                setTimeout(() => setShowReportSuccessToast(false), 3000); // Hide toast after 3 seconds
                getPreviousData()
                setInitialReportFile(sonarFile.name)
                setIsInitialReportSubmitted(true)
                setIsSubmitClicked(true);
                return result.data;

            } else {
                setError('Upload Failed');
                setInitialReportFile("")
            }
        } catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "uploadSonarReport",
                errorSource: "frontend",
                errorDiscription: "Upload failed" + error.message
            }
            await postErrorLog(errorData)
        }
    }

    //PC_68-PC_73
    const handleInitialSonarFile = (e: any) => {
        const file = e.target.files[0];
        const allowedExtensions = ['pdf'];
        const fileExtension = file?.name.split('.').pop()?.toLowerCase();

        if (file && allowedExtensions.includes(fileExtension)) {
            if (file.size <= 2 * 1024 * 1024) {
                setSonarFile(file);
                setInitialReportFile(file.name);
                setError('');
            } else {
                setError('File limit exceeds. Upload a file below 2000 KB.');
            }
        } else {
            setError('The uploaded file is not in a valid format. Please upload a PDF file.');
        }

        e.target.value = null;
    };

    //PC_75-PC_80
    const handleFinalSonarFile = (e: any) => {
        const file = e.target.files[0];
        const allowedExtensions = ['pdf'];
        const fileExtension = file?.name.split('.').pop()?.toLowerCase();

        if (file && allowedExtensions.includes(fileExtension)) {
            if (file.size <= 2 * 1024 * 1024) {
                setSonarFile(file);
                setFinalReportFile(file.name);
                setError('');
            } else {
                setError('File limit exceeds. Upload a file below 2000 KB.');
            }
        } else {
            setError('The uploaded file is not in a valid format. Please upload a PDF file.');
        }
        e.target.value = null
    };


    //PC_44-PC_46
    const handleApply = async () => {
        let filter = filterData;

        if (filter.startDate && !filter.endDate) {
            filter.endDate = getCurrentDate()
            setFilterData({ ...filterData, endDate: getCurrentDate() });
        }

        else if (!filter.startDate && filter.endDate) {
            filter.startDate = staticStartDate
            setFilterData({ ...filterData, startDate: staticStartDate });
        }
        await getPreviousData(search, filter);
        setNoRecordsMsg("No Records Found");
    };


    //PC_48-PC_49
    const handleClear = () => {
        setFilterData({
            startDate: "",
            endDate: "",
            targetFramework: "",
            status: 0,
            projectType: ""
        });
        setTargetFramework([]);
        setStatus([]);
        setEnnableFilterButton(true);
        setSearch("");
        getPreviousData("", filterObj)
        setProjectType([])
        getTargetFramework()
    };

    //PC_41-PC_43: call FilterValidation()
    function filterValidation(e: any) {

        const elementId = e.target.id;
        let elementValue = e.target.value;

        switch (elementId) {
            case "status": {
                elementValue = parseInt(elementValue);
                break;
            }
            case "startDate": {
                if (filterData.endDate && elementValue > filterData.endDate) {
                    setFilterData({ ...filterData, startDate: elementValue, endDate: elementValue });
                } else {
                    setFilterData({ ...filterData, startDate: elementValue });
                }
                break;
            }
            case "endDate": {
                if (elementValue < filterData.startDate) {
                    setFilterData({ ...filterData, endDate: filterData.startDate });
                } else {
                    setFilterData({ ...filterData, endDate: elementValue });
                }
                break;
            }
            default: {
                setFilterData({ ...filterData, [elementId]: elementValue });
                break;
            }
        }

        setFilterData((prevFilterData) => ({
            ...prevFilterData,
            [elementId]: elementValue,
        }));
        if (elementValue !== "") {
            setEnnableFilterButton(false);
        } else {
            setEnnableFilterButton(true);
        }

    }
    //PC_108-PC_118
    async function getTargetFramework() {
        try {
            let response: any = await fetchTargetFramework({});
            if (response.status === 200) {
                const result = response.data;
                setTargetFramework(result.targetFrameworks);
                setProjectType(result.projectTypes);
                setStatus(result.statusDetails);
            }
            else {
                navigate("/error");
            }

            return response;
        }

        catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "getTargetFramework",
                errorSource: "frontend",
                errorDiscription: "Couldnt fetch Target framework drop down" + error.message
            }

            await postErrorLog(errorData)
        }
    }

    //PC_17-PC_34: Created getPreviousData and handled search,sort,filter,loadMore
    async function getPreviousData(emptydata = search, filterdata = filterData) {
        try {

            let sortSearchObj: sortSearchObj = {
                sort: sort,
                sortOrder: sortOrder,
                search: emptydata.trim(),
                filterData: filterdata,
                loadCount: loadCount,
            };

            let result = await getActivityLog(sortSearchObj);

            if (result?.status === 200) {
                if (result.data && result.data.projects) {
                    setMyActivityDatas(result.data.projects);
                    setTotalActivityLogCount(result.data.count);
                    return result
                }
                else {
                    setMyActivityDatas([]);
                    setTotalActivityLogCount(0);
                    if (emptydata.trim() !== "") {
                        setNoRecordsMsg("No Search Record Found");
                    }
                    setNoRecordsHtml(
                        <tr>
                            <td colSpan={8}>
                                <div className="text-center mb-5 table-no-record">
                                    <img src="img/no-record.svg" alt="no-record-img" className="mb-5" />
                                    <p className="text-center font-30 font-medium color-black">Oops!</p>
                                    <p className="text-center font-16 font-regular grey-v2" style={{ fontWeight: "bold" }}>{noRecordsMsg}</p>
                                </div>
                            </td>
                        </tr>
                    )
                    return result
                }
            }

        }
        catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "getPreviousData",
                errorSource: "frontend",
                errorDiscription: "Could not fetch Project data " + error.message
            }
            await postErrorLog(errorData)
        }

    }

    //PC_202-PC_207
    const openModal = (report: any) => {
        setSelectedReport(report);
        setIsInitialReportSubmitted(report.initialSonarReportName ? true : false);
        setIsSubmitClicked(report.initialSonarReportName ? true : false);
        setIsSubmitClickedFinal(report.finalSonarReportFileName ? true : false)
        setInitialReportFile(report.initialSonarReportName);
        setFinalReportFile(report.finalSonarReportFileName);
        setShowReportButtonModal(true);
        setActiveTab('InitialReport');
    };

    //PC_183
    const closeModal = () => {
        setShowReportButtonModal(false);
        setInitialReportFile("")
        setFinalReportFile("")
        setError('')
    };


    const formatDate = (dateValue: any) => {
        if (!dateValue) return "-";

        const momentDate = moment(dateValue, moment.ISO_8601, true);

        if (momentDate.isValid()) {
            return momentDate.format("LT");
        } else {
            // If it's not a valid ISO string, try parsing as a Unix timestamp
            const unixDate = moment.unix(dateValue);
            return unixDate.isValid() ? unixDate.format("LT") : "-";
        }
    };

    //PC_NP_12.20 - 12.24
    async function getAgentStatus() {
        try {
            let response: any = await fetchAgentStatus({});
            if (response.statusCode === 200) {
                let result = response.data;
                // PC_NP_12.25
                setMyActivityDatas(prevData => {
                    return prevData.map(project => {
                        const matchingResult = result.find((r: any) => r.projectId === project.projectId);
                        if (matchingResult) {
                            return {
                                ...project,
                                statusId: matchingResult.statusId,
                                orcAgentStatus: matchingResult.orcAgentStatus,
                                winAgentStatus: matchingResult.winAgentStatus,
                                conAgentStatus: matchingResult.conAgentStatus,
                                valAgentStatus: matchingResult.valAgentStatus,
                                testAgentStatus: matchingResult.testAgentStatus,
                                debuggerAgentStatus: matchingResult.debuggerAgentStatus,
                                executorAgentStatus: matchingResult.executorAgentStatus,
                                durationMinutes: matchingResult.durationMinutes,
                                agentIteration: matchingResult.agentIteration
                            };
                        }
                        return project;
                    });
                });
                //PC_NP_12.26 - 12.27
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response;
        }
        //PC_NP_12.28 - PC_NP_12.30
        catch (error: any) {
            let errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "getAgentStatus",
                errorSource: "frontend",
                errorDiscription: "Couldn't fetch agent status " + error.message
            }

            await postErrorLog(errorData);
        }
    }

    //PC_35 -PC_38: bindActivityLog() function for the logs to display
    //PC_229-PC_232 : updates in progress div 
    function bindActivityLog() {

        return (
            myActivityDatas.length > 0 ? (
                myActivityDatas.map((value) => (
                    <tr key={value.projectId} className="custom-border-bottom">
                        <td className="text-nowrap">{value.projectName}</td>
                        <td>{value.targetFramework}</td>
                        <td>{value.projectType}</td>
                        <td>
                            {moment(value.createdAt).format("L") + "  " + moment(value.createdAt).format("LT")}
                        </td>
                        <td>
                            <div className="d-flex align-items-center">
                                <span className={value.statusId == 'Completed' ? "status-completed me-2" : "status-inprogress me-2"}></span>
                                <span className="font-14">{value.statusId}</span>
                            </div>
                        </td>
                        <td>
                            {
                                (() => {
                                    // Parse the durationMinutes and pausePlayMinutes to integer values
                                    let durationMinutes = parseInt(value.durationMinutes);
                                    const pausePlayMinutes = value.conAgentStatus ? value.pausePlayDuration : 0;

                                    // Subtract pausePlayMinutes only if conAgentStatus is not null
                                    durationMinutes = value.conAgentStatus ? durationMinutes - pausePlayMinutes : durationMinutes;

                                    // Ensure the durationMinutes doesn't go negative after subtraction
                                    durationMinutes = Math.max(0, durationMinutes);

                                    // Convert the duration to hours and minutes if greater than 59
                                    if (durationMinutes > 59) {
                                        const hours = Math.floor(durationMinutes / 60);
                                        const minutes = durationMinutes % 60;
                                        return `${hours} hr ${minutes} mins`;
                                    } else {
                                        // If less than 60 minutes, simply show the minutes
                                        return `${durationMinutes} mins`;
                                    }
                                })()
                            }

                        </td>
                        <td className="text-center">
                            <div className="d-flex justify-content-center">
                                <div className="progress-container-grid">
                                    <div className={`progress-step-grid ${value.orcAgentStatus ? 'completed' : 'inprogress'}`}>
                                        <div className={`step-icon-grid ${value.orcAgentStatus ? 'completed' : 'inprogress'}`} data-bs-toggle="tooltip" data-bs-placement="top" title={`Initialization: ${formatDate(value.orcAgentStatus)}`}> 1</div>
                                    </div>
                                    <div className={`progress-step-grid ${value.winAgentStatus ? 'completed' : value.orcAgentStatus ? 'inprogress' : ''}`}>
                                        <div className={`step-icon-grid ${value.winAgentStatus ? 'completed' : value.orcAgentStatus ? 'inprogress' : ''}`} data-bs-toggle="tooltip" data-bs-placement="top" title={`Extraction: ${formatDate(value.winAgentStatus)}`}>2</div>
                                    </div>
                                    <div className={`progress-step-grid ${value.conAgentStatus ? 'completed' : value.winAgentStatus ? 'inprogress' : ''}`}>
                                        <div className={`step-icon-grid ${value.conAgentStatus ? 'completed' : value.winAgentStatus ? 'inprogress' : ''}`} data-bs-toggle="tooltip" data-bs-placement="top" title={`Conversion: ${formatDate(value.conAgentStatus)}`}>3</div>
                                    </div>
                                    <div className={`progress-step-grid ${value.valAgentStatus ? 'completed' : value.conAgentStatus ? 'inprogress' : ''}`}>
                                        <div className={`step-icon-grid ${value.valAgentStatus ? 'completed' : value.conAgentStatus ? 'inprogress' : ''}`} data-bs-toggle="tooltip" data-bs-placement="top" title={`Validation: ${formatDate(value.valAgentStatus)}`}>4</div>
                                    </div>
                                    <div className={`progress-step-grid ${value.testAgentStatus ? 'completed' : value.valAgentStatus ? 'inprogress' : ''}`}>
                                        <div className={`step-icon-grid ${value.testAgentStatus ? 'completed' : value.valAgentStatus ? 'inprogress' : ''}`} data-bs-toggle="tooltip" data-bs-placement="top" title={`Test Case Generation: ${formatDate(value.testAgentStatus)}`}>5</div>
                                    </div>
                                    <div className={`progress-step-grid ${value.debuggerAgentStatus ? 'completed' : value.testAgentStatus ? 'inprogress' : ''}`}>
                                        <div className={`step-icon-grid ${value.debuggerAgentStatus ? 'completed' : value.testAgentStatus ? 'inprogress' : ''}`} data-bs-toggle="tooltip" data-bs-placement="top" title={`Execution: ${formatDate(value.debuggerAgentStatus)}  Iteration:${value.agentIteration !== null ? ` ${value.agentIteration}` : ' 0'}`}>6</div>
                                    </div>
                                    <div className={`progress-step-grid ${value.executorAgentStatus ? 'completed' : value.debuggerAgentStatus ? 'inprogress' : ''}`}>
                                        <div className={`step-icon-grid ${value.executorAgentStatus ? 'completed' : value.debuggerAgentStatus ? 'inprogress' : ''}`} data-bs-toggle="tooltip" data-bs-placement="top" title={`Debugging:${formatDate(value.executorAgentStatus)}`}>7</div>
                                    </div>
                                    <div className={`progress-step-grid ${value.executorAgentStatus ? 'completed' : value.executorAgentStatus ? 'inprogress' : ''}`}>
                                        <div className={`step-icon-grid ${value.executorAgentStatus ? 'completed' : value.executorAgentStatus ? 'inprogress' : ''}`} data-bs-toggle="tooltip" data-bs-placement="top" title={`Completion: ${formatDate(value.executorAgentStatus)}`}>8</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="d-flex justify-content-center">
                            <button className="btn outine-button rounded-3 mx-1" disabled={value.statusId == 'Completed' ? false : true} onClick={() => navigate('/Output', { state: { projectId: value.projectId, projectName: value.projectName, targetFramework: value.targetFramework, initialSonarReportName: value.initialSonarReportName, finalSonarReportFileName: value.finalSonarReportFileName, projectType: value.projectType } })}>
                                <img src="./img/view-icon.svg" />
                            </button>
                            <button className="btn outine-button rounded-3 mx-1" disabled={value.statusId == 'Completed' ? false : true} onClick={() => downloadConvertedFile(value)}
                            >
                                <img src="./img/download-grey.svg" />
                            </button>
                            <button className="btn outine-button rounded-3 mx-1" onClick={() => openModal(value)}>
                                <img src="./img/document.svg" />
                            </button>
                            <button className="btn outine-button rounded-3 mx-1" onClick={() => { handleRetryClick(value) }} disabled={value.statusId === 'Completed' || isRetryButtonDisabled(value.retryTime)}>
                                <img src="./img/reset-icon.svg" />
                            </button>
                            {value.playPauseStatus === 'play' ? (
                                <button className="btn outine-button rounded-3 mx-1" onClick={() => { handlePlayPauseBtn(value) }} disabled={!(value.winAgentStatus !== null && value.conAgentStatus === null)}>
                                    <img src="./img/pause.svg" alt="Pause" />
                                </button>
                            ) : (
                                <button className="btn outine-button rounded-3 mx-1" onClick={() => { handlePlayPauseBtn(value) }} disabled={!(value.winAgentStatus !== null && value.conAgentStatus === null)}>
                                    <img src="./img/play.svg" alt="Play" />
                                </button>
                            )}

                        </td>
                    </tr>
                ))
            ) : (
                noRecordsHtml
            )
        );
    }

    //PC_208 - PC_210
    const getCurrentDate = () => {
        const today = new Date();
        let month = '' + (today.getMonth() + 1);
        let day = '' + today.getDate();
        const year = today.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    //PC_222
    const isValidInput = (input: string): boolean => {
        const regex = /^[a-zA-Z0-9_-]*$/;
        return regex.test(input);
    };

    //PC_211-PC_214
    const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!isValidInput(e.key)) {
            e.preventDefault();
        }
    };

    //PC_223-PC_227
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text');
        const validPastedText = pastedText.replace(/[^a-zA-Z0-9_-]/g, '');
        const newValue = search + validPastedText;
        setSearch(newValue.slice(0, 30));
        getPreviousData(newValue.slice(0, 30));
    };

    //PC_215-PC_219
    const searchRecord = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (isValidInput(newValue)) {
            setSearch(newValue);
            getPreviousData(newValue);
            setNoRecordsMsg(myActivityDatas.length === 0 ? "No Search Record Found" : "");
        }
    };


    const handleInsideClick = (e: any) => {
        // Only stop propagation if the click is not on the close icon
        if (!(e.target).closest('.close-icon')) {
            e.stopPropagation();
        }
    };

    return (
        <>
            {isLoading ? <Loader isLoading={isLoading} /> :
                <>
                    <Header />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="px-4 py-4 mt-3 mb-3 rounded-3">
                                    <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 ">
                                        <h5 className="font-20 font-bold mb-0">
                                            My Projects
                                        </h5>
                                        <div className="d-flex gap-3 flex-wrap w-sm-100">
                                            <div className="d-flex gap-3 flex-row-reverse flex-md-row w-sm-100 my-projects">
                                                <div className="input-group input-search custom-border rounded-2">
                                                    <input type="search" className="form-control border-0 font-14 font-regular"
                                                        placeholder="Search"
                                                        value={search}
                                                        onChange={(e) => { searchRecord(e) }}
                                                        onKeyDown={(e) => { blockInvalidChar(e) }}
                                                        autoComplete='off'
                                                        maxLength={30}
                                                        onPaste={handlePaste}
                                                    />
                                                    <button className="btn border-0 bg-white pt-0 focus-none" type="button"><img
                                                        src="./img/search-icon.svg" alt="search"
                                                        onClick={() => {
                                                            getPreviousData();
                                                        }} /></button>
                                                </div>

                                                <button className="btn custom-border filter-active bg-white" data-bs-toggle="dropdown" aria-expanded="false">
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-end custom-filter p-4 " style={{ position: "absolute", inset: "0px auto auto 0px", margin: "0px", transform: "translate(897px, 148px)" }}
                                                    aria-labelledby="dropdownMenuLink" onClick={handleInsideClick}>
                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <h5 className="font-bold font-18 mb-0 custom-header-color">Advanced Filter</h5>
                                                        <span className="close-icon"><img className="cursor-pointer" src="./img/cross.svg" alt="close" /></span>
                                                    </div>

                                                    <div className="row">

                                                        <div className="col-md-6 mb-4">
                                                            <label className="form-label font-14 font-medium label-color "
                                                                htmlFor="status">Converted To</label>
                                                            <select className="form-select" aria-label="Default select example" id="targetFramework" value={filterData.targetFramework} onChange={filterValidation}>
                                                                <option value="" disabled>Select</option>
                                                                {targetFramework && targetFramework.length > 0 && targetFramework.map((value: any) => (
                                                                    <option key={value.frameworkId} value={value.frameworkId}>{value.frameworkName}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="col-md-6 mb-4">
                                                            <label className="form-label font-14 font-medium label-color "
                                                                htmlFor="status">Project type</label>
                                                            <select className="form-select" aria-label="Default select example" id="projectType" value={filterData.projectType} onChange={filterValidation}>
                                                                <option value="" disabled>Select</option>
                                                                {projectType && projectType.length > 0 && projectType.map((value: any) => (
                                                                    <option key={value.projectTypeId} value={value.projectTypeId}>{value.projectTypeName}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="col-md-6 mb-4">
                                                            <label className="form-label font-14 font-medium label-color "
                                                                htmlFor="status">Status</label>
                                                            <select className="form-select" aria-label="Default select example" id="status" value={filterData.status} onChange={filterValidation}>
                                                                <option value={0} disabled>Select</option>
                                                                {status && status.length > 0 && status.map((eachStatus: any) => (
                                                                    <option key={eachStatus.statusId} value={eachStatus.statusId}>{eachStatus.statusField}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="col-md-12 mb-2">
                                                            <label className="font-15 font-bold custom-header-color">Created Date
                                                                Range</label>
                                                        </div>

                                                        <div className="col-md-6 mb-4 calender-input">
                                                            <label className="form-label font-14 font-medium label-color" htmlFor="startDate">From</label>
                                                            <input
                                                                key={`startDate-${resetKey}`}
                                                                className="form-control"
                                                                type="date"
                                                                id="startDate"
                                                                onChange={filterValidation}
                                                                value={filterData.startDate ? new Date(filterData.startDate).toISOString().split('T')[0] : ''}
                                                                max={getCurrentDate()}


                                                            />
                                                            <img className="custom-calendar-icon"
                                                                src="./img/calendar-icon.svg"
                                                                alt="calendar-icon"
                                                                style={{ cursor: 'pointer' }}
                                                            />

                                                        </div>

                                                        <div className="col-md-6 mb-4 calender-input">
                                                            <label className="form-label font-14 font-medium label-color" htmlFor="endDate">To</label>
                                                            <input key={`endDate-${resetKey}`}
                                                                className="form-control"
                                                                type="date"
                                                                id="endDate"
                                                                onChange={filterValidation}
                                                                value={filterData.endDate ? new Date(filterData.endDate).toISOString().split('T')[0] : ''}
                                                                min={filterData.startDate}
                                                            />
                                                            <img className="custom-calendar-icon"
                                                                src="./img/calendar-icon.svg"
                                                                alt="calendar-icon"
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </div>


                                                        <div className="col-md-12">
                                                            <div className="d-flex justify-content-end align-items-center mt-3 mb-1">
                                                                <button
                                                                    id="clear-filter-button"
                                                                    className="close-icon btn secondary-button font-medium font-13 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4 rounded-3"
                                                                    onClick={() => handleClear()}
                                                                >Clear</button>
                                                                <button
                                                                    id="apply"
                                                                    className="close-icon btn primary-button  font-medium font-13 d-flex gap-2 justify-content-center align-items-center px-4 py-2 rounded-3 border-0"
                                                                    disabled={enableFilterButton}
                                                                    onClick={() => handleApply()}>
                                                                    Apply
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                className="btn w-sm-100 primary-button text-nowrap  small-device-100 font-medium font-14 d-flex gap-2 justify-content-center align-items-center border-0 text-white" onClick={() => navigate('/NewProject')}>

                                                <img src="./img/plus-white.svg" alt="add-plus-icon" />New Project

                                            </button>
                                        </div>
                                    </div>


                                    <div className="table-responsive theme-table bg-white rounded-2 table-box-shadow">
                                        <table className="table table-borderless mb-0 align-middle font-14">
                                            <thead>
                                                <tr>
                                                    <th className="text-nowrap align-items-center py-4">
                                                        <div className="d-flex ">
                                                            <h4 className="font-bold font-14 mb-0">Project Name</h4>
                                                            <span className="ms-2 cursor-pointer mt-1" hidden={myActivityDatas.length === 0}>
                                                                <img className="d-block" src="./img/sort-up.svg" alt="sort-arrow-down" onClick={() => {
                                                                    setSort("projectName")
                                                                    setSortOrder("asc");
                                                                }} />
                                                                <img className="d-block" src="./img/sort-down.svg" alt="sort-arrow-down" onClick={() => {
                                                                    setSort("projectName")
                                                                    setSortOrder("desc");
                                                                }} />
                                                            </span>
                                                        </div>
                                                    </th>


                                                    <th className="text-nowrap align-items-center py-4">
                                                        <div className="d-flex ">
                                                            <h4 className="font-bold font-14 mb-0">Converted To</h4>
                                                            <span className="ms-2 cursor-pointer mt-1" hidden={myActivityDatas.length === 0}>
                                                                <span><img className="d-block" src="./img/sort-up.svg"
                                                                    alt="sort-arrow-down"
                                                                    onClick={() => {
                                                                        setSort("targetFramework");
                                                                        setSortOrder("asc");
                                                                    }}
                                                                /></span>
                                                                <span><img className="d-block" src="./img/sort-down.svg"
                                                                    alt="sort-arrow-down"
                                                                    onClick={() => {
                                                                        setSort("targetFramework");
                                                                        setSortOrder("desc");
                                                                    }}
                                                                /></span>
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <th className="text-nowrap align-items-center py-4">
                                                        <div className="d-flex ">
                                                            <h4 className="font-bold font-14 mb-0">Project Type</h4>
                                                            <span className="ms-2 cursor-pointer mt-1" hidden={myActivityDatas.length === 0}>
                                                                <span><img className="d-block" src="./img/sort-up.svg"
                                                                    alt="sort-arrow-down"
                                                                    onClick={() => {
                                                                        setSort("projectType");
                                                                        setSortOrder("asc");
                                                                    }}
                                                                /></span>
                                                                <span><img className="d-block" src="./img/sort-down.svg"
                                                                    alt="sort-arrow-down"
                                                                    onClick={() => {
                                                                        setSort("projectType");
                                                                        setSortOrder("desc");
                                                                    }}
                                                                /></span>
                                                            </span>
                                                        </div>
                                                    </th>

                                                    <th className="text-nowrap align-items-center py-4">
                                                        <div className="d-flex ">
                                                            <h4 className="font-bold font-14 mb-0">Date & Time</h4>
                                                            <span className="ms-2 cursor-pointer mt-1" hidden={myActivityDatas.length === 0}>
                                                                <span><img className="d-block" src="./img/sort-up.svg"
                                                                    alt="sort-arrow-down"
                                                                    onClick={() => {
                                                                        setSort("createdAt");
                                                                        setSortOrder("asc");
                                                                    }
                                                                    } /></span>
                                                                <span><img className="d-block" src="./img/sort-down.svg"
                                                                    alt="sort-arrow-down"
                                                                    onClick={() => {
                                                                        setSort("createdAt");
                                                                        setSortOrder("desc");
                                                                    }}
                                                                /></span>
                                                            </span>
                                                        </div>
                                                    </th>

                                                    <th className="text-nowrap align-items-center py-4">
                                                        <div className="d-flex">
                                                            <h4 className="font-bold font-14 mb-0">Status</h4>
                                                            <span className="ms-2 cursor-pointer mt-1" hidden={myActivityDatas.length === 0}>
                                                                <span><img className="d-block" src="./img/sort-up.svg"
                                                                    alt="sort-arrow-down"
                                                                    onClick={() => {
                                                                        setSort("statusId");
                                                                        setSortOrder("asc");
                                                                    }}
                                                                /></span>
                                                                <span><img className="d-block" src="./img/sort-down.svg"
                                                                    alt="sort-arrow-down"
                                                                    onClick={() => {
                                                                        setSort("statusId");
                                                                        setSortOrder("desc");
                                                                    }}
                                                                /></span>
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <th className="text-nowrap align-items-center py-4">
                                                        <div className="d-flex">
                                                            <h4 className="font-bold font-14 mb-0">Duration</h4>
                                                            <span className="ms-2 cursor-pointer mt-1" hidden={myActivityDatas.length === 0}>
                                                                <span><img className="d-block" src="./img/sort-up.svg"
                                                                    alt="sort-arrow-down"
                                                                    onClick={() => {
                                                                        setSort("durationMinutes");
                                                                        setSortOrder("asc");
                                                                    }}
                                                                /></span>
                                                                <span><img className="d-block" src="./img/sort-down.svg"
                                                                    alt="sort-arrow-down"
                                                                    onClick={() => {
                                                                        setSort("durationMinutes");
                                                                        setSortOrder("desc");
                                                                    }}
                                                                /></span>
                                                            </span>
                                                        </div>
                                                    </th>


                                                    <th className="text-nowrap py-4">
                                                        <div className="d-flex  justify-content-center">
                                                            <h4 className="font-bold font-14 mb-0">Conversion Progress</h4>
                                                        </div>
                                                    </th>

                                                    <th className="text-nowrap align-items-center py-4">
                                                        <div className="d-flex  justify-content-center">
                                                            <h4 className="font-bold font-14 mb-0">Actions</h4>

                                                        </div>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bindActivityLog()}
                                            </tbody>


                                        </table>
                                    </div>
                                    <p className="font-16 font-medium mt-4 grey-v2">
                                        # of Records: <span className="font-bold color-black">{Math.min(loadCount, totalActivityLogCount)}</span> out of <span className="font-bold color-black">{totalActivityLogCount}</span>
                                    </p >

                                    <div className="d-flex justify-content-center mt-4">
                                        <button
                                            hidden={totalActivityLogCount === 0 || totalActivityLogCount <= loadCount}
                                            className="btn outine-button font-14 font-medium bg-white load-more-btn"
                                            onClick={() => { setLoadCount(prevCount => prevCount + 10) }}
                                        >
                                            Load More
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a hidden={true} ref={downloadLinkRef} ></a>

                    </div>
                    <div className="modal fade show" id="staticBackdrop" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" style={
                        !showReportButtonModal
                            ? { display: "none" }
                            : { display: "block", backgroundColor: "rgba(0,0,0,0.6)" }
                    }>


                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-0 px-4 pb-0">
                                    <h1 className="modal-title font-bold color-black font-18" id="exampleModalLabel">Sonar Analysis Report</h1>
                                    <button type="button" className="btn-close" onClick={() => closeModal()} aria-label="Close"></button>
                                </div>
                                <div className="modal-body px-4 pt-2">

                                    <div className=" custom-tabs mt-3">
                                        <ul className="nav nav-pills d-flex mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link m-0 cus-tab-link cus-tab-border custom-tab-list p-0 grey-v4 font-medium font-14 ${activeTab === 'InitialReport' ? 'active' : ''}`}
                                                    id="InitialReport-tab"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="InitialReport"
                                                    aria-selected={activeTab === 'InitialReport'}
                                                    onClick={() => { setActiveTab('InitialReport'); setError(""); }}>
                                                    Initial Report
                                                </button>
                                            </li>

                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link m-0 cus-tab-link cus-tab-border custom-tab-list p-0 grey-v4 font-medium font-14 ${activeTab === 'FinalReport' ? 'active' : ''}`}
                                                    id="FinalReport-tab"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="FinalReport"
                                                    aria-selected={activeTab === 'FinalReport'}
                                                    disabled={!isInitialReportSubmitted || !selectedReport.statusId.includes('Completed')}
                                                    onClick={() => { setActiveTab('FinalReport'); setError(""); }} >
                                                    Final Report
                                                </button>
                                            </li>
                                        </ul>


                                        <div className="tab-content" id="pills-tabContent">
                                            <div className={`tab-pane fade ${activeTab === 'InitialReport' ? 'show active' : ''} pt-4`}
                                                id="InitialReport" role="tabpanel"
                                                aria-labelledby="InitialReport-tab">
                                                <label htmlFor="UploadFile" className="pb-2 font-medium font-14 color-black">Upload
                                                    File</label>
                                                <span className="upload-btn-wrapper d-block">
                                                    <button type="button"
                                                        className="upload-btn cursor-pointer py-4 font-14"
                                                        disabled={initialReportFile !== "" ? true : false}
                                                    >
                                                        <img src="img/upload.svg" alt="Upload" className="me-2 cloud-icon" />Choose file or
                                                        Drag and Drop to Upload File
                                                    </button>
                                                    <input type="file" name="myfile"
                                                        className="upload-btn cursor-pointer py-4 font-14 "
                                                        id="OrganizationLogo"
                                                        onChange={(e) => handleInitialSonarFile(e)}
                                                        disabled={initialReportFile !== "" ? true : false}
                                                    />
                                                </span>

                                                {initialReportFile && !isFileHidden.initial && (
                                                    <div className="uploaded-content my-2 px-2 py-1">

                                                        <div className="d-flex align-items-center">


                                                            <span className="font-medium"> {initialReportFile}{' '} <img
                                                                src="img/close-icon.svg" alt="close-icon"
                                                                className="cursor-pointer ms-1" onClick={() => removeInitialReport()} /></span>
                                                        </div>

                                                    </div>
                                                )}


                                                {error &&
                                                    <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                                                <div className="modal-footer gap-2 text-end pt-5 px-0 border-0">
                                                    <button type="button" className="btn secondary-button px-4 font-13 color-black font-medium" onClick={() => closeModal()}>Cancel</button>
                                                    <button type="button" className="btn px-4 submit-btn font-13 color-black font-medium" onClick={() => uploadSonarReport()} disabled={initialReportFile !== "" && sonarFile != null ? false : true}>Submit</button>
                                                    <button type="button" className="btn primary-button border-0 px-4 font-medium font-13" onClick={() => downloadSonarReport()} disabled={initialReportFile == "" || isSubmitClicked !== true ? true : false}
                                                    ><img src="img/download.svg" alt="download-icon" className="me-2" />Download</button>
                                                </div>
                                            </div>

                                            <div className={`tab-pane fade ${activeTab === 'FinalReport' ? 'show active' : ''} pt-4`}
                                                id="FinalReport" role="tabpanel"
                                                aria-labelledby="FinalReport-tab">
                                                <label htmlFor="UploadFile" className="pb-2 font-14 font-medium color-black">Upload
                                                    File</label>
                                                <span className="upload-btn-wrapper d-block">
                                                    <button type="button"
                                                        className="upload-btn cursor-pointer py-4 font-14"
                                                        disabled={finalReportFile !== "" ? true : false}>
                                                        <img src="img/upload.svg" alt="Upload"
                                                            className="me-2 cloud-icon" />Choose file or
                                                        Drag and Drop to Upload File
                                                    </button>
                                                    <input type="file" name="myfile" className="cursor-pointer cust-file"
                                                        id="CloudLogo"
                                                        onChange={(e) => handleFinalSonarFile(e)}
                                                        disabled={finalReportFile !== "" ? true : false}
                                                    />
                                                </span>
                                                {finalReportFile && !isFileHidden.final && (
                                                    <div className="uploaded-content my-2 px-2 py-1">

                                                        <div className="d-flex align-items-center">
                                                            <span className="font-medium"> {finalReportFile}{' '}
                                                                <img src="img/close-icon.svg" alt="close-icon" className="cursor-pointer ms-1" onClick={() => removeFinalReport()} />
                                                            </span>
                                                        </div>

                                                    </div>
                                                )}
                                                {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}

                                                <div className="modal-footer gap-2 text-end pt-5 px-0 border-0">
                                                    <button type="button" className="btn secondary-button px-4 font-13 color-black font-medium" onClick={() => closeModal()}>Cancel</button>
                                                    <button type="button" className="btn px-4 submit-btn font-13 color-black font-medium" onClick={() => uploadFinalSonarReport()} disabled={finalReportFile !== "" && sonarFile != null ? false : true}>Submit</button>
                                                    <button type="button" className="btn primary-button border-0 px-4 font-medium font-13" onClick={() => downloadFinalSonarReport()} disabled={finalReportFile == "" || isSubmitClickedFinal !== true ? true : false}
                                                    ><img src="img/download.svg" alt="download-icon" className="me-2" />Download</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    {showReportSuccessToast && (
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: '10px', left: '50%', transform: 'translateX(-50%)' }} >
                            <div className="custom-toast-success d-flex align-items-center gap-3 m-4 shadow-sm px-3 py-2">
                                <img src="img/upload-green.svg" alt="upload-green" />
                                <p className="font-16 font-bold color-black m-0">
                                    <span className="d-block">Uploaded</span>
                                    <span className="m-0 font-14 font-medium">
                                        Report has been uploaded successfully
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}
                    {showDownloadSuccessToast && (
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                            <div className="custom-toast-success d-flex align-items-center gap-3 m-4 shadow-sm px-3 py-2">
                                <img src="img/download-toast.svg" alt="download-green" />
                                <p className="font-16 font-bold color-black m-0">
                                    <span className="d-block">Downloaded</span>
                                    <span className="m-0 font-14 font-medium">
                                        File has been downloaded successfully
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}
                    {showDownloadErrorToast && (
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                            <div className="custom-toast-failed d-flex align-items-center gap-3 m-4 shadow-sm px-3 py-2">
                                <img src="img/download-failed-toast.svg" alt="download-red" />
                                <p className="font-16 font-bold color-black m-0">
                                    <span className="d-block">Download Failed</span>
                                    <span className="m-0 font-14 font-medium">
                                        Error in downloading the file
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}
                </>
            }
        </>

    )
}
export default LandingPageNew;

