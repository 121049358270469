import { client } from "./client";

//PC_LC_21 - PC_LC_25
export const getLogin = async (code: string) => {
    let result = await client(`userService/login?code=${code}`, {}, "GET");
    return result;
};

//PC_LC_52 - PC_LC_55
export async function postLogData(userLog: any) {
    let result = await client("userService/postUserLog", userLog, "POST");
    return result;
    
}

//PS_LC_64
export async function postErrorLog(errorData: any) {
    let result = await client("userService/postErrorLog", errorData, "POST")
    return result;
}


//PC_NP_27 - PC_NP_33
export async function postProjectDetails(projectDetails: any) {
    let result = await client("userService/postProjectDetails", projectDetails, "POST")
    return result;
}

//PC_NP_33.15 - 33.20
export async function fetchAgentStatus(payload : any) {
    let result = await client("getAgentStatus", payload, "GET")
    return result;
}


