
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Editor from '@monaco-editor/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { postErrorLog } from "../Service/api";
import { uploadSonarReportFile, downloadSonarReportFile, fetchdownloadConvertedFile, previewConvertedFile, uploadFinalSonarReportFile, downloadFinalSonarReportFile, deleteFinalSonarReport, deleteInitialSonarReport } from '../Service/landingApi';
import Header from './Header';
import Loader from './Loader';

interface ProjectData {
  projectId: number;
  projectName: string;
  targetFramework: string;
  src: Package[];
}

interface ModalData {
  initialSonarReport: string;
  initialSonarReportName: string;
  finalSonarReport: string;
  finalSonarReportFileName: string;
}

interface Package {
  packageName: string;
  files: FileData[];
}

interface FileData {
  name: string;
  fileName: string;
  fileContent: string;
}

const ProjectComponent: React.FC = () => {
  //PC_OC_02
  const [output, setOutput] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<FileData | null>(null);
  const { state } = useLocation();
  const [projectData, setProjectData] = useState<ProjectData | null>(null);
  const [showReportButtonModal, setShowReportButtonModal] = useState<boolean>(false);
  const [initialReportFile, setInitialReportFile] = useState("");
  const [finalReportFile, setFinalReportFile] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [showReportSuccessToast, setShowReportSuccessToast] = useState(false);
  const [isFileHidden, setIsFileHidden] = useState({ initial: false, final: false });
  const [showDownloadSuccessToast, setShowDownloadSuccessToast] = useState(false);
  const [showDownloadErrorToast, setShowDownloadErrorToast] = useState(false)
  const [isInitialReportSubmitted, setIsInitialReportSubmitted] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [modalData, setmodalData] = useState<ModalData | null>(null);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitClickedFinal, setIsSubmitClickedFinal] = useState(false);
  const [activeTab, setActiveTab] = useState('InitialReport');


  const [sonarFile, setSonarFile] = useState<File>();
  const monacoTargetTextOptions = {
    readOnly: true,
    minimap: {
      enabled: false
    }
  };

  //PC_02
  const navigate = useNavigate();

  useEffect(() => {

    if (state && state.projectId) {
      setmodalData(state)
      previewConvertedFrameworkFile(state.projectId);
    }
  }, [state]);


  //PC_OC_85-PC_OC_97
  const previewConvertedFrameworkFile = async (projectId: number) => {
    try {
      setIsLoading(true)
      const response = await previewConvertedFile(projectId);
      setIsLoading(false)
      if (response === undefined) {
        return
      }

      if (response.status === 200) {

        const result: ProjectData = response.data;
        setProjectData(result)
        setOutput(result.src?.length > 0 && result.src[0]?.files.length > 0 ? result.src[0].files[0].fileContent : "");
      }

    } catch (error: any) {
      let errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorfunctionName: "previewConvertedFrameworkFile",
        errorSource: "frontend",
        errorDiscription: "Preview failed" + error.message
      }

      await postErrorLog(errorData)
    }
  };

  //PC_129-PC_148
  async function downloadSonarReport(projectId: any) {
    try {
      let result = await downloadSonarReportFile(projectId);
      if (result && result.data) {
        var blob = new Blob([result.data], { type: 'application/pdf' });
        var url = window.URL.createObjectURL(blob);

        if (downloadLinkRef.current) {
          downloadLinkRef.current.href = url;
          downloadLinkRef.current.download = initialReportFile;
          downloadLinkRef.current.click();

          window.URL.revokeObjectURL(url);
        }
        closeModal()
        setShowDownloadSuccessToast(true);
        setTimeout(() => setShowDownloadSuccessToast(false), 3000);
      } else {
        setError('download failed');
        setShowDownloadErrorToast(true);
        setTimeout(() => setShowDownloadErrorToast(false), 3000);
      }
    }
    catch (error: any) {
      let errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorfunctionName: "downloadSonarReport",
        errorSource: "frontend",
        errorDiscription: "Report Download failed" + error.message
      }
      setShowDownloadErrorToast(true);
      setTimeout(() => setShowDownloadErrorToast(false), 3000);
      await postErrorLog(errorData)
    }
  }

  //PC_198-PC_199
  async function downloadFinalSonarReport(projectId: any) {
    try {
      // const projectId = selectedReport
      let result = await downloadFinalSonarReportFile(projectId);
      if (result && result.data) {

        let binaryData = new Uint8Array(result.data);

        var blob = new Blob([binaryData], { type: 'application/pdf' });
        var url = window.URL.createObjectURL(blob);

        if (downloadLinkRef.current) {
          downloadLinkRef.current.href = url;
          downloadLinkRef.current.download = finalReportFile;
          downloadLinkRef.current.click();
          window.URL.revokeObjectURL(url);
        }
        closeModal()
        setShowDownloadSuccessToast(true);
        setTimeout(() => setShowDownloadSuccessToast(false), 3000);
      } else {
        setError('download failed');
        setShowDownloadErrorToast(true);
        setTimeout(() => setShowDownloadErrorToast(false), 3000);
      }
    }

    catch (error: any) {
      let errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorfunctionName: "downloadFinalSonarReport",
        errorSource: "frontend",
        errorDiscription: "Report Download failed" + error.message
      }
      setShowDownloadErrorToast(true);
      setTimeout(() => setShowDownloadErrorToast(false), 3000);
      await postErrorLog(errorData)
    }
  }



  //PC_OC_04-PC_OC_14
  async function downloadConvertedFile(projectId: any) {
    try {
      setIsLoading(true)
      let result = await fetchdownloadConvertedFile(projectId);
      setIsLoading(false)
      if (result && result.data) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([result.data], { type: 'application/zip' }));
        link.setAttribute('download', state.projectName + ".zip");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setShowDownloadSuccessToast(true);
        setTimeout(() => setShowDownloadSuccessToast(false), 3000); // Hide toast after 3 seconds
      } else {
        setShowDownloadErrorToast(true);
        setTimeout(() => setShowDownloadErrorToast(false), 3000); // Hide toast after 3 seconds
      }
    } catch (error: any) {
      let errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorfunctionName: "downloadConvertedFile",
        errorSource: "frontend",
        errorDiscription: "Converted File download failed" + error.message
      }
      await postErrorLog(errorData)
    }
  }

  //PC_185-PC_190
  async function removeInitialReport(projectId: any) {
    try {
      let result: any = await deleteInitialSonarReport(projectId);
      if (result && result.status === 204) {
        setInitialReportFile("")
        setIsInitialReportSubmitted(false);
        setIsSubmitClicked(false)
        setmodalData(prevModalData => prevModalData ? {
          ...prevModalData,
          initialSonarReport: "",
          initialSonarReportName: ""
        } : null);

      }
      else {
        setError('couldnt remove file');
      }

    } catch (error: any) {
      let errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorfunctionName: "removeInitialReport",
        errorSource: "frontend",
        errorDiscription: "Initial report removal failed" + error.message
      }
      await postErrorLog(errorData)
    }
  }

  //PC_191
  async function removeFinalReport(projectId: any) {
    try {
      let result: any = await deleteFinalSonarReport(projectId);
      if (result && result.status === 204) {
        setFinalReportFile("")
        setIsSubmitClickedFinal(false)
        setmodalData(prevModalData => prevModalData ? {
          ...prevModalData,
          finalSonarReport: "",
          finalSonarReportName: ""
        } : null);
      }
      else {
        setError('couldnt remove file');
      }

    } catch (error: any) {
      let errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorfunctionName: "removeFinallReport",
        errorSource: "frontend",
        errorDiscription: "Final report removal failed" + error.message
      }
      await postErrorLog(errorData)
    }
  }



  //PC_OC_37-PC_OC_44

  async function uploadSonarReport() {
    try {

      if (sonarFile === undefined) {
        return
      }
      let result: any = await uploadSonarReportFile(sonarFile, state.projectId);
      if (result && result.status === 200) {
        setSonarFile(undefined)
        setShowReportButtonModal(false);
        setShowReportSuccessToast(true);

        setTimeout(() => setShowReportSuccessToast(false), 3000);
        setIsInitialReportSubmitted(true)
        setIsSubmitClicked(true)
        setmodalData(prevModalData => prevModalData ? {
          ...prevModalData,
          initialSonarReportName: sonarFile?.name
        } : null);
        return result.data;

      } else {
        setError('upload failed');
      }
    } catch (error: any) {
      let errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorfunctionName: "uploadSonarReport",
        errorSource: "frontend",
        errorDiscription: "Upload failed" + error.message
      }
      await postErrorLog(errorData)
    }
  }

  //PC_200-PC_201
  async function uploadFinalSonarReport() {
    try {

      if (sonarFile === undefined) {
        return
      }
      let result: any = await uploadFinalSonarReportFile(sonarFile, state.projectId);

      if (result && result.status === 200) {
        setSonarFile(undefined)
        setShowReportButtonModal(false);
        setShowReportSuccessToast(true);


        setTimeout(() => setShowReportSuccessToast(false), 3000); // Hide toast after 3 seconds
        setIsInitialReportSubmitted(true)
        setIsSubmitClickedFinal(true);
        setmodalData(prevModalData => prevModalData ? {
          ...prevModalData,
          finalSonarReportFileName: sonarFile?.name
        } : null);
        return result.data;

      } else {
        setError('upload failed');
      }
    } catch (error: any) {
      let errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorfunctionName: "uploadSonarReport",
        errorSource: "frontend",
        errorDiscription: "Upload failed" + error.message
      }
      await postErrorLog(errorData)
    }
  }

  //PC_OC_22-PC_OC_27

  const handleInitialSonarFile = (e: any) => {
    const file = e.target.files[0];
    const allowedExtensions = ['pdf'];
    const fileExtension = file?.name.split('.').pop()?.toLowerCase();

    if (file && allowedExtensions.includes(fileExtension)) {
      if (file.size <= 2 * 1024 * 1024) {
        setSonarFile(file);
        setInitialReportFile(file.name);
        setError('');
      } else {
        setError('File limit exceeds. Upload a file below 2000 KB.');
      }
    } else {
      setError('The uploaded file is not in a valid format. Please upload a PDF file.');
    }

    e.target.value = null;
  };

  //PC_OC_29-PC_OC_35

  const handleFinalSonarFile = (e: any) => {
    const file = e.target.files[0];
    const allowedExtensions = ['pdf'];
    const fileExtension = file?.name.split('.').pop()?.toLowerCase();

    if (file && allowedExtensions.includes(fileExtension)) {
      if (file.size <= 2 * 1024 * 1024) {
        setSonarFile(file);
        setFinalReportFile(file.name);
        setError('');
      } else {
        setError('File limit exceeds. Upload a file below 2000 KB.');
      }
    } else {
      setError('The uploaded file is not in a valid format. Please upload a PDF file.');
    }
    e.target.value = null
  };




  //PC_OC_98-PC_OC_99
  const handleFileClick = (file: any) => {
    setSelectedFile(file);
    setOutput(file.fileContent);
  };


  //PC_OC_100
  const closeModal = () => {
    setShowReportButtonModal(false);
    setInitialReportFile("")
    setFinalReportFile("")
    setError('')
  };

  //PC_202-PC_207
  const openModal = (modalData: any) => {
    setIsInitialReportSubmitted(modalData.initialSonarReportName ? true : false);
    setIsSubmitClicked(modalData.initialSonarReportName ? true : false);
    setIsSubmitClickedFinal(modalData.finalSonarReportFileName ? true : false);
    setInitialReportFile(modalData.initialSonarReportName);
    setFinalReportFile(modalData.finalSonarReportFileName);
    setShowReportButtonModal(true);
    setActiveTab('InitialReport');
  };

  return (
    <>
      {isLoading ? <Loader isLoading={isLoading} /> :
        <>
          <Header />
          <div className="parent-container p-5">
            <div className="project-container bg-white custom-border p-5">
              <div className="d-flex align-items-center w-50">
                <a href="#" className="back-arrow" onClick={() => navigate('/Landing')}>
                  <img src="./img/left-arrow.svg" alt="left-arrow" />
                </a>
                <h4 className="font-20 font-bold mb-0 ms-2">New Project</h4>
              </div>
              <div className="d-flex justify-content-center">
                <div className="p-4 h-100 my-3 w-50">
                  <div className="progress-container mt-4">
                    <div className="progress-step completed">
                      <div className="step-icon completed">
                        <img src="img/check-mark.svg" alt="check-mark-icon" />
                      </div>
                      <div className="step-label font-bold font-12">Upload</div>
                    </div>
                    <div className="progress-step completed">
                      <div className="step-icon completed">
                        <img src="img/check-mark.svg" alt="check-mark-icon" />
                      </div>
                      <div className="step-label font-bold font-12 color-black">Output</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <h4 className="font-18 font-bold custom-margin-left">Project Information</h4>

                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4 mt-3 mb-5 px-4">
                        <label className="form-label font-14 font-medium" htmlFor="ProjectName">Project Name <span className="color-red"></span></label>
                        <input
                          className="form-control custom-border font-13 font-regular grey-v4 px-3"
                          type="text"
                          placeholder="Enter Project Name"
                          defaultValue={state.projectName || ""}
                          disabled />
                      </div>
                      <div className="col-md-4 mt-3 mb-5 px-4">
                        <label className="form-label font-14 font-medium" htmlFor="ConvertTo">Convert To <span className="color-red"></span></label>
                        <input
                          type="text"
                          className="form-control custom-border font-13 font-regular grey-v4 px-3"
                          id="ConvertFrom"
                          defaultValue={state.targetFramework || ""}
                          disabled />
                      </div>
                      <div className="col-md-4 mt-3 mb-5 px-4">
                        <label className="form-label font-14 font-medium" htmlFor="ConvertTo">Project Type <span className="color-red"></span></label>
                        <input
                          type="text"
                          className="form-control custom-border font-13 font-regular grey-v4 px-3"
                          id="ConvertFrom"
                          defaultValue={state.projectType || ""}
                          disabled />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4  mb-5 ps-4 position-relative">
                        <h4 className="font-18 font-bold  mb-3">Project Files</h4>
                        <div className="accordion custom-height custom-scroll custom-border" id="accordionExample">
                          {projectData?.src !== undefined && projectData.src?.length > 0 ? (
                            projectData.src.map((pkg, pkgIndex) => (
                              <div className="accordion-item" key={pkgIndex}>
                                <h2 className="accordion-header" id={`heading${pkgIndex}`}>
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${pkgIndex}`}
                                    aria-expanded="true"
                                    aria-controls={`collapse${pkgIndex}`}
                                  >
                                    <img
                                      src="img/folder-icon-violet.svg"
                                      className="folder-icon-size me-2"
                                    />
                                    {pkg.packageName}
                                  </button>
                                </h2>
                                <div
                                  id={`collapse${pkgIndex}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`heading${pkgIndex}`}
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div className="accordion" id={`accordionFiles${pkgIndex}`}>
                                      {pkg.files.map((file, fileIndex) => (
                                        <div className="accordion-item" key={fileIndex}>
                                          <h2 className="accordion-header" id={`fileHeading${pkgIndex}-${fileIndex}`}>
                                            <button
                                              className="accordion-button child collapsed"
                                              type="button"
                                              onClick={() => handleFileClick(file)}
                                              title={file.fileName}
                                            >
                                              <img src="img/code.svg "
                                                className="folder-icon-size me-2" />

                                              {file?.fileName ? (
                                                file.fileName.length > 22 ?
                                                  file.fileName.slice(0, 22) + "..." :
                                                  file.fileName
                                              ) : ""}
                                            </button>
                                          </h2>
                                          <div
                                            id={`fileCollapse${pkgIndex}-${fileIndex}`}
                                            className="accordion-collapse collapse"
                                            aria-labelledby={`fileHeading${pkgIndex}-${fileIndex}`}
                                            data-bs-parent={`#accordionFiles${pkgIndex}`}
                                          >
                                            <div className="accordion-body">
                                              <div onClick={() => handleFileClick(file)}>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div>No data available</div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-8 position-relative">
                        <h4 className="font-18 font-bold mb-3">Converted Solution</h4>
                        <Editor
                          className="custom-height"
                          height="40vh"
                          defaultLanguage="typescript"
                          defaultValue=""
                          options={{ readOnly: true }}
                          theme="vs-dark"
                          value={output} />
                      </div>
                    </div>


                    <div className="d-flex justify-content-end align-items-center mt-5 mb-3">
                      <div className="d-flex align-items-center">
                        <button className="btn submit-btn font-medium font-13 d-flex gap-2 justify-content-center align-items-center px-4 py-2 me-3 rounded-3" onClick={() => openModal(modalData)}>
                          Report
                        </button>

                        <a hidden={true} ref={downloadLinkRef}></a>

                        <div className="modal fade show" id="staticBackdrop" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true" style={!showReportButtonModal
                          ? { display: "none" }
                          : { display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>



                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header border-0 px-4 pb-0">
                                <h1 className="modal-title font-bold color-black font-18" id="exampleModalLabel">Sonar Analysis Report</h1>
                                <button type="button" className="btn-close" onClick={() => closeModal()} aria-label="Close"></button>
                              </div>
                              <div className="modal-body px-4 pt-2">

                                <div className=" custom-tabs mt-3">
                                  <ul className="nav nav-pills d-flex mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                      <button
                                        className={`nav-link m-0 cus-tab-link cus-tab-border custom-tab-list p-0 grey-v4 font-medium font-14 ${activeTab === 'InitialReport' ? 'active' : ''}`}
                                        id="InitialReport-tab"
                                        type="button"
                                        role="tab"
                                        aria-controls="InitialReport"
                                        aria-selected={activeTab === 'InitialReport'}
                                        onClick={() => { setActiveTab('InitialReport'); setError(""); }}
                                      >
                                        Initial Report
                                      </button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                      <button
                                        className={`nav-link m-0 cus-tab-link cus-tab-border custom-tab-list p-0 grey-v4 font-medium font-14 ${activeTab === 'FinalReport' ? 'active' : ''}`}
                                        id="FinalReport-tab"
                                        type="button"
                                        role="tab"
                                        aria-controls="FinalReport"
                                        aria-selected={activeTab === 'FinalReport'}
                                        disabled={!isInitialReportSubmitted}
                                        onClick={() => { setActiveTab('FinalReport'); setError(""); }}
                                      >
                                        Final Report
                                      </button>
                                    </li>
                                  </ul>


                                  <div className="tab-content" id="pills-tabContent">
                                    <div className={`tab-pane fade ${activeTab === 'InitialReport' ? 'show active' : ''} pt-4`}
                                      id="InitialReport" role="tabpanel"
                                      aria-labelledby="InitialReport-tab">
                                      <label htmlFor="UploadFile" className="pb-2 font-medium font-14 color-black">Upload
                                        File</label>
                                      <span className="upload-btn-wrapper d-block">
                                        <button type="button"
                                          className="upload-btn cursor-pointer py-4 font-14"
                                          disabled={initialReportFile !== "" ? true : false}>
                                          <img src="img/upload.svg" alt="Upload" className="me-2 cloud-icon" />Choose file or
                                          Drag and Drop to Upload File
                                        </button>
                                        <input type="file" name="myfile"
                                          className="cursor-pointer cust-file"
                                          id="OrganizationLogo"
                                          onChange={(e) => handleInitialSonarFile(e)}
                                          disabled={initialReportFile !== "" ? true : false} />
                                      </span>

                                      {initialReportFile && !isFileHidden.initial && (
                                        <div className="uploaded-content my-2 px-2 py-1">

                                          <div className="d-flex align-items-center">


                                            <span className="font-medium"> {initialReportFile}{' '} <img
                                              src="img/close-icon.svg" alt="close-icon"
                                              className="cursor-pointer ms-1" onClick={() => removeInitialReport(state.projectId)} /></span>
                                          </div>

                                        </div>
                                      )}



                                      {error &&
                                        <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                                      <div className="modal-footer gap-2 text-end pt-5 px-0 border-0">
                                        <button type="button" className="btn secondary-button px-4 font-13 color-black font-medium" onClick={() => closeModal()}>Cancel</button>
                                        <button type="button" className="btn px-4 submit-btn font-13 color-black font-medium" onClick={() => uploadSonarReport()} disabled={initialReportFile !== "" && sonarFile != null ? false : true}>Submit</button>
                                        <button type="button" className="btn primary-button border-0 px-4 font-medium font-13" onClick={() => downloadSonarReport(state.projectId)} disabled={initialReportFile == "" || isSubmitClicked !== true ? true : false}
                                        ><img src="img/download.svg" alt="download-icon" className="me-2" />Download</button>
                                      </div>
                                    </div>

                                    <div className={`tab-pane fade ${activeTab === 'FinalReport' ? 'show active' : ''} pt-4`}
                                      id="FinalReport" role="tabpanel"
                                      aria-labelledby="FinalReport-tab">
                                      <label htmlFor="UploadFile" className="pb-2 font-14 font-medium color-black">Upload
                                        File</label>
                                      <span className="upload-btn-wrapper d-block">
                                        <button type="button"
                                          className="upload-btn cursor-pointer py-4 font-14"
                                          disabled={finalReportFile !== "" ? true : false}
                                        >
                                          <img src="img/upload.svg" alt="Upload"
                                            className="me-2 cloud-icon" />Choose file or
                                          Drag and Drop to Upload File
                                        </button>
                                        <input type="file" name="myfile" className="cursor-pointer cust-file"
                                          id="CloudLogo"
                                          onChange={(e) => handleFinalSonarFile(e)}
                                          disabled={finalReportFile !== "" ? true : false} />
                                      </span>
                                      {finalReportFile && !isFileHidden.final && (
                                        <div className="uploaded-content my-2 px-2 py-1">

                                          <div className="d-flex align-items-center">
                                            <span className="font-medium"> {finalReportFile}{' '}
                                              <img src="img/close-icon.svg" alt="close-icon" className="cursor-pointer ms-1" onClick={() => removeFinalReport(state.projectId)} />
                                            </span>
                                          </div>

                                        </div>
                                      )}
                                      {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}

                                      <div className="modal-footer gap-2 text-end pt-5 px-0 border-0">
                                        <button type="button" className="btn secondary-button px-4 font-13 color-black font-medium" onClick={() => closeModal()}>Cancel</button>
                                        <button type="button" className="btn px-4 submit-btn font-13 color-black font-medium" onClick={() => uploadFinalSonarReport()} disabled={finalReportFile !== "" && sonarFile != null ? false : true}>Submit</button>
                                        <button type="button" className="btn primary-button border-0 px-4 font-medium font-13" onClick={() => downloadFinalSonarReport(state.projectId)} disabled={finalReportFile == "" || !isSubmitClickedFinal}
                                        ><img src="img/download.svg" alt="download-icon" className="me-2" />Download</button>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>


                        {showReportSuccessToast && (
                          <div className="d-flex justify-content-center" style={{ position: 'fixed', top: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                            <div className="custom-toast-success d-flex align-items-center gap-3 m-4 shadow-sm px-3 py-2">
                              <img src="img/upload-green.svg" alt="upload-green" />
                              <p className="font-16 font-bold color-black m-0">
                                <span className="d-block">Uploaded</span>
                                <span className="m-0 font-14 font-medium">
                                  Report has been uploaded successfully
                                </span>
                              </p>
                            </div>
                          </div>
                        )}

                        {showDownloadSuccessToast && (
                          <div className="d-flex justify-content-center" style={{ position: 'fixed', top: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                            <div className="custom-toast-success d-flex align-items-center gap-3 m-4 shadow-sm px-3 py-2">
                              <img src="img/download-toast.svg" alt="download-green" />
                              <p className="font-16 font-bold color-black m-0">
                                <span className="d-block">Downloaded</span>
                                <span className="m-0 font-14 font-medium">
                                  File has been downloaded successfully
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="d-flex align-items-center">
                        <button className="btn submit-btn font-medium font-13 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4 rounded-3 " onClick={() => navigate('/Landing')}>
                          Finish
                        </button>
                        <button className="btn primary-button font-medium font-13 d-flex gap-2 justify-content-center align-items-center px-4 py-2 rounded-3 border-0" onClick={() => downloadConvertedFile(state.projectId)}>
                          <img src="img/dowload-btn.svg" alt="download-icon" />
                          Download
                        </button>
                        {showDownloadSuccessToast && (
                          <div className="d-flex justify-content-center" style={{ position: 'fixed', top: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                            <div className="custom-toast-success d-flex align-items-center gap-3 m-4 shadow-sm px-3 py-2">
                              <img src="img/download-toast.svg" alt="download-green" />
                              <p className="font-16 font-bold color-black m-0">
                                <span className="d-block">Downloaded</span>
                                <span className="m-0 font-14 font-medium">
                                  File has been downloaded successfully
                                </span>
                              </p>
                            </div>
                          </div>
                        )}



                        {showDownloadErrorToast && (
                          <div className="d-flex justify-content-center" style={{ position: 'fixed', top: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                            <div className="custom-toast-failed d-flex align-items-center gap-3 m-4 shadow-sm px-3 py-2">
                              <img src="img/download-failed-toast.svg" alt="download-red" />
                              <p className="font-16 font-bold color-black m-0">
                                <span className="d-block">Download Failed</span>
                                <span className="m-0 font-14 font-medium">
                                  Error in downloading the file
                                </span>
                              </p>
                            </div>
                          </div>
                        )}


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>}
    </>
  );
};

export default ProjectComponent;